<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{$t('MODALIMPRESSAO.IMPRESSAO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless is-marginless">
            <div class="panel">
                <p class="panel-heading has-text-centered" :class="conexaoStatusClass">{{$t('MODALIMPRESSAO.CONEXAO')}}: <b>{{ conexaoStatus }}</b></p>
                <div class="panel-block">
                    <b-field label="Servidor:" horizontal style="width:100%">
                        <b-input placeholder="Caminho do servidor de impressão"
                                 icon-right="sync"
                                 icon-right-clickable
                                 @icon-right-click="reconectarWebsocket"
                                 v-model="laborWebsocketAddress">
                        </b-input>
                    </b-field>
                </div>
                <div v-for="(impressora, index) in filasImpressoras" v-bind:key="index">
                    <p class="panel-heading has-background-light">{{ impressora.nome }}</p>
                    <div class="panel-block">
                        <div class="columns is-mobile is-multiline">
                            <div class="column" style="display:block;">
                                <b-select v-model="impressora.printId" :key="index">
                                    <option v-for="(option, index) in impressorasDisponiveis"
                                            :value="option" v-bind:key="index">
                                        {{ option }}
                                    </option>
                                </b-select>
                            </div>
                            <div class="column has-text-centered">
                                <div>
                                    <p class="title">{{ impressora.jobs.length }}</p>
                                </div>
                            </div>
                            <div v-if="impressora.jobs.length > 0" class="column">
                                <b-button icon-left="printer-off"
                                          type="is-danger"
                                          title="Remover todos" @click="limparFilaDeImpressao(impressora.nome)">
                                </b-button>
                            </div>
                            <div class="column">
                                <div>
                                    <b-switch size="is-small" v-model="impressora.printAuto" :key="index" title="Imprimir automaticamente">Auto</b-switch>
                                </div>
                            </div>
                            <div v-if="impressora.jobs.length > 0" class="column">
                                <b-button v-if="conexaoAtiva"
                                          icon-left="printer-check"
                                          title="Imprimir todos" @click="imprimirFilaDeImpressao(impressora.nome)">
                                    {{$t('MODALIMPRESSAO.IMPRIMIR')}}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer class="modal-card-foot" style="display: block;">
            <button class="button is-success is-pulled-right" type="button" @click="salvarConfiguracoes();$parent.close();">{{$t('MODALIMPRESSAO.SALVAR')}}</button>
            <button class="button is-pulled-right" type="button" @click="$parent.close()"> {{$t('MODALIMPRESSAO.FECHAR')}}</button>
            
        </footer>
    </div>
</template>

<script>
import { mapState } from 'vuex';

    export default {
        computed: {
            conexaoAtiva() {
                return this.$store.state.laborlisWebsocket.status === "Ativa";
            },
            conexaoStatusClass() {
                return "has-background-" + this.$store.state.laborlisWebsocket.statusClass;
            },
            conexaoStatus() {
                return this.$store.state.laborlisWebsocket.status;
            },
            impressorasDisponiveis() {
                return this.$store.state.laborlisWebsocket.impressorasDisponiveis;
            },
            laborWebsocketAddress: {
                get() {
                    return this.$store.state.laborlisWebsocket.address
                },
                set(value) {
                    this.$store.commit('laborlisWebsocket/SET_ADDRESS', value)
                }
            },
            ...mapState('laborlisWebsocket', [
                'filasImpressoras'
            ]),
        },
        methods: {
            salvarConfiguracoes() {
                this.$store.dispatch('laborlisWebsocket/salvarConfiguracoes', null, { root: true })
            },
            reconectarWebsocket() {
                this.$laborwebsocket.connect();
            },
            limparFilaDeImpressao(impressora) {
                this.$store.commit('laborlisWebsocket/CLEAN_FILA_IMPRESSAO', impressora, { root: true })
            },
            imprimirFilaDeImpressao(impressora) {
                this.salvarConfiguracoes();

                const printid = localStorage.getItem("printid_" + impressora);
                if (printid === null) {
                    alert("Impressora " + impressora + " não configurada!");
                    return;
                }
                const printjobs = localStorage.getItem("printjobs_" + impressora);
                const printjobsList = eval(printjobs);
                if (printjobsList === null) {
                    alert("Não há nenhuma tarefa na fila da impressora '" + impressora + "'!");
                    return;
                }
                for (let i = 0; i < printjobsList.length; i++) {
                    const job = printjobsList[i];
                    if (job.linhasDeCodigo.length === 1) {
                        const linhas = job.linhasDeCodigo[0].substring(1, job.linhasDeCodigo[0].length - 1);
                        let linhasArray = linhas.split('\\n').join('\n');
                        if (linhasArray.startsWith("XA")) {
                            linhasArray = "^" + linhasArray;
                        }
                        while (linhasArray.indexOf("\\") !== -1) {
                            linhasArray = linhasArray.replace("\\", "");
                        }
                        this.$laborwebsocket.print(impressora, printid, linhasArray, job.dados);
                    }
                    else {
                        this.$laborwebsocket.print(impressora, printid, job.linhasDeCodigo.join('\n'), job.dados);
                    }
                }
                this.limparFilaDeImpressao(impressora);
            }
        }
    }
</script>