

<template class="paddingsidebar">
    
    <transition name="fade">
        
        <div v-if="!sidebarIsOpened">
            <b-tooltip label="Abrir o menu" type="is-dark" position="is-right">
                <a class="navbar-item" @click="SET_SIDEBAR(true)">
                    <img src="../assets/icon/arrow.svg" width="22" height="22">
                </a>
            </b-tooltip>
        </div>
        <div class="column is-2 sidebar-default" v-if="sidebarIsOpened" >
            <aside class="menu paddingsidebar">
                <button class="butclose is-pulled-right closebar" @click="SET_SIDEBAR(false)">
                    <b-tooltip label="Esconder o menu" type="is-dark" position="is-bottom">
                        <img style="transform: scaleX(-1);" src="../assets/icon/arrow.svg" width="22" height="22">
                    </b-tooltip>
                </button>

                <span v-if="!config.logoBase64" class="navbar-item">
                    <img src="/logo-icon.png" />
                </span>

                <a @click="$router.push(`/`)">
                    <img v-if="config.logoBase64" :src="'data:image/png;base64, ' + config.logoBase64" style="max-width: 150px; max-height: 30px; width: auto !important; height: auto !important;" />
                    <span v-else>{{ config.nomeDoLaboratorio || "LaborLis" }}</span>
                </a>
                <hr class="dropdown-divider">
                <div v-if="atendendo" style="display: flex; justify-content: center;">
                    Senha de Atendimento
                    <b-tooltip position="is-bottom" type="is-dark" label="Cancelar Senha">
                        <a @click="removerAtendendo" class="column is-1">
                            <b-icon icon="close-circle" type="is-danger"></b-icon>
                        </a>
                    </b-tooltip>
                </div>
                <div v-if="atendendo" style="display: flex" class="columns">

                    <b-taglist attached class="column is-12" style="margin-bottom: 0;">
                        <b-tag :type="atendendo.buttonType" size="is-medium" class=" has-text-weight-bold" style="width: 8rem">{{ atendendo.tipo }}</b-tag>
                        <b-tag type="is-warning" size="is-medium" class="has-text-weight-bold" style="width: 1rem">{{ atendendo.sequencia }}</b-tag>

                    </b-taglist>


                </div>
                <div v-if="atendendo">
                    <hr class="dropdown-divider" style="margin-top: 0;">
                </div>





                <div style="padding: 3%;margin-top: 3%;background-color: #e0e0e0;border-radius: 10px;">
                    <div>
                        <div style="display: flex; align-items: center; justify-content: center;">
                            <div style="margin: 0% 6% 0% 6%;">
                                <img src="../assets/icon/guiacod2.svg" width="22" height="22" @click="showField('field1')">
                            </div>

                            <div style="margin: 0% 6% 0% 6%;">
                                <img src="../assets/icon/ref3.svg" width="22" height="22" @click="showField('field2')">
                            </div>

                            <div style="margin: 0% 6% 0% 6%;">
                                <img src="../assets/icon/amostracod.svg" width="22" height="22" @click="showField('field3')">
                            </div>

                            <div style="margin: 0% 6% 0% 6%;">
                                <img src="../assets/icon/ref3.svg" width="22" height="22" @click="showField('field4')">
                            </div>
                        </div>
                    </div>
                    <div>
                        <div id="field1" class="field" v-show="activeField === 'field1'" style="background-color: white;border-radius: 5px; padding: 1%; margin-bottom: 0;">
                            <label class="labelxs" style="color: black;">Código da guia</label>
                            <div class="control">
                                <form @submit.prevent="abrirGuia">
                                    <b-input class="inputch" v-model="abrirGuiaId" type="number" insideSidebar expanded></b-input>
                                </form>
                            </div>
                        </div>
                        <div id="field2" class="field" v-show="activeField === 'field2'" style="background-color: white;border-radius: 5px; padding: 1%; margin-bottom: 0;">
                            <label class="labelxs" style="color: black;">Referência da guia</label>
                            <div class="control">
                                <form @submit.prevent="abrirGuiaRef">
                                    <b-input v-model="abrirGuiaReferencia" insideSidebar expanded></b-input>
                                </form>
                            </div>
                        </div>
                        <div id="field3" class="field" v-show="activeField === 'field3'" style="background-color: white;border-radius: 5px; padding: 1%; margin-bottom: 0;">
                            <label class="labelxs" style="color: black;">Código da Amostra</label>
                            <div class="control">
                                <form @submit.prevent="abrirGuiaAmo">
                                    <b-input v-model="abrirGuiaAmostra" insideSidebar expanded></b-input>
                                </form>
                            </div>
                        </div>
                        <div id="field4" class="field" v-show="activeField === 'field4'" style="background-color: white;border-radius: 5px; padding: 1%; margin-bottom: 0;">
                            <label class="labelxs" style="color: black;">Referência da Amostra</label>
                            <div class="control">
                                <form @submit.prevent="abrirGuiaAmoRef">
                                    <b-input v-model="abrirGuiaAmostraReferencia" insideSidebar expanded></b-input>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>



                <hr class="dropdown-divider">

                <div v-if="this.unidadeNome != null">
                    <ul class="menu-list colorSide">
                        <li class="colorSides sidebar-item">
                            <a href="/home" class="dropdown-trigger" style="display: flex; align-items: center;">
                                <img src="../assets/icon/home.svg" width="22" height="22">
                                &nbsp;&nbsp;&nbsp;&nbsp;{{$t('SIDEBAR.HOME')}}
                            </a>
                        </li>

                        <li class="colorSides sidebar-item" v-if="isInRole('agenda-consultar')">
                            <a @click="handleClickAgenda" @mouseover="handleMouseover" class="dropdown-trigger" style="display: flex; align-items: center;">
                                <img src="../assets/icon/calendar.svg" width="22" height="22">
                                &nbsp;&nbsp;&nbsp;&nbsp;{{$t('SIDEBAR.AGENDA')}} <span style="margin-left: auto;"><b-icon :icon="isMobile ? (isSubmenuVisibleAgenda ? 'chevron-up' : 'chevron-down') : 'chevron-right'" size="is-big"></b-icon></span>
                            </a>
                            <div :class="{'sidebar-submenu': !isMobile}" v-show="isSubmenuVisibleAgenda">
                                <router-link :to="{ name: 'inspecao' }" v-if="isInRole('agenda-consultar')">Agendar</router-link>
                                <router-link :to="{ name: 'agenda-relacao', query: { unidadeId: unidadeId } }" v-if="isInRole('agenda-consultar')">Agendamentos</router-link>
                                <router-link :to="{ name: 'agenda' }" v-if="isInRole('agenda-consultar')">Calendário</router-link>
                            </div>
                        </li>
                        <li class="colorSides sidebar-item" v-if="isInRole('menu-atendimento')">
                            <a href="/atendimento" @click="handleClickAtendimento" @mouseover="handleMouseover" class="dropdown-trigger" style="display: flex; align-items: center;">
                                <img src="../assets/icon/guia2.svg" width="22" height="22">
                                &nbsp;&nbsp;&nbsp;&nbsp;{{$t('SIDEBAR.ATENDIMENTO')}} <span style="margin-left: auto;"><b-icon :icon="isMobile ? (isSubmenuVisibleAtendimento ? 'chevron-up' : 'chevron-down') : 'chevron-right'" size="is-big"></b-icon></span>
                            </a>
                            <div :class="{'sidebar-submenu': !isMobile}" v-show="isSubmenuVisibleAtendimento">
                                <template class="menumobile">
                                    <router-link :to="{ name: 'atendimento' }" v-if="isInRole('menu-atendimento')"> Menu Atendimento</router-link>
                                    <hr style="margin: 0 !important; background-color: #e0e0e0; border: none; display: block; height: 2px;">
                                </template>
                                <router-link :to="{ name: 'pacientes', query: { unidadeId: unidadeId }, params: {criarGuia: true} }" v-if="isInRole('paciente-consultar')">Novo</router-link>
                                <hr style="margin: 0 !important; background-color: #e0e0e0; border: none; display: block; height: 2px;">
                                <router-link :to="{ name: 'guia-list', query: { unidadeId: unidadeId } }" v-if="isInRole('guia-consultar')">Atendimentos</router-link>
                                <router-link :to="{ name: 'pacientes'}" v-if="isInRole('paciente-consultar')">Pacientes</router-link>
                                <router-link :to="{ name: 'orcamentos' }" v-if="isInRole('orcamento-consultar')">Orçamentos</router-link>
                                <hr style="margin: 0 !important; background-color: #e0e0e0; border: none; display: block; height: 2px;">
                                <router-link :to="{ name: 'sala-espera', query: { unidadeId: unidadeId } }" v-if="isInRole('salaespera-consultar')">Sala de espera</router-link>
                                <hr style="margin: 0 !important; background-color: #e0e0e0; border: none; display: block; height: 2px;">
                                <a @click="abrirSenhasAtendimento">{{$t('ATENDIMENTO.SENHAS')}}</a>
                            </div>
                        </li>

                        <li class="colorSides sidebar-item" v-if="isInRole('guia-receber-amostra')">
                            <a @click="handleClickColeta" @mouseover="handleMouseover" class="dropdown-trigger" style="display: flex; align-items: center;">
                                <img src="../assets/icon/exame.svg" width="22" height="22">
                                &nbsp;&nbsp;&nbsp;&nbsp;{{$t('SIDEBAR.EXAME')}} <span style="margin-left: auto;"><b-icon :icon="isMobile ? (isSubmenuVisibleColeta ? 'chevron-up' : 'chevron-down') : 'chevron-right'" size="is-big"></b-icon></span>
                            </a>
                            <div :class="{'sidebar-submenu': !isMobile}" v-show="isSubmenuVisibleColeta">
                                <router-link :to="{ name: 'coleta' }" v-if="isInRole('guia-receber-amostra')">Coleta</router-link>
                                <router-link :to="{ name: 'amostralotecheckpoint', query: { unidadeId: unidadeId } }">Checkpoint</router-link>
                                <router-link :to="{ name: 'amostraloteenviar', query: { unidadeId: unidadeId } }">Envio de amostras</router-link>
                                <router-link :to="{ name: 'amostralotelistar'}" v-if="isInRole('guia-receber-amostra-fora-lote')">Lote de amostras</router-link>
                                <router-link :to="{ name: 'recebimentoamostras' }" v-if="isInRole('guia-receber-amostra')">Recebimento de amostras</router-link>
                                <hr style="margin: 0 !important; background-color: #e0e0e0; border: none; display: block; height: 2px;">
                                <router-link :to="{ name: 'procuradeexames' }">Busca de exames</router-link>
                                <router-link :to="{ name: 'procuradeamostras' }">Busca de amostras</router-link>
                                <hr style="margin: 0 !important; background-color: #e0e0e0; border: none; display: block; height: 2px;">
                                <a :href="'/Guia/RotaColetaDomiciliarHoje?unidadeId=' + unidadeId">Rota de Coleta</a>
                            </div>
                        </li>

                        <li class="colorSides sidebar-item" v-if="isInRole('menu-analise')">
                            <a href="/analise" @click="handleClickAnalise" @mouseover="handleMouseover" class="dropdown-trigger" style="display: flex; align-items: center;">
                                <img src="../assets/icon/analise.svg" width="22" height="22">
                                &nbsp;&nbsp;&nbsp;&nbsp;{{$t('SIDEBAR.ANALISE')}} <span style="margin-left: auto;"><b-icon :icon="isMobile ? (isSubmenuVisibleAnalise ? 'chevron-up' : 'chevron-down') : 'chevron-right'" size="is-big"></b-icon></span>
                            </a>
                            <div :class="{'sidebar-submenu': !isMobile}" v-show="isSubmenuVisibleAnalise">
                                <div class="menumobile">
                                    <router-link :to="{ name: 'analise' }" v-if="isInRole('menu-analise')"> Menu Análise</router-link>
                                    <hr style="margin: 0 !important; background-color: #e0e0e0; border: none; display: block; height: 2px;">
                                </div>
                                <router-link :to="{ name: 'digitacao-assinatura' }" v-if="isInRole('resultado-editar') || isInRole('resultado-assinar')">Digitação/Assinatura</router-link>
                                <hr style="margin: 0 !important; background-color: #e0e0e0; border: none; display: block; height: 2px;">
                                <router-link :to="{ name: 'exames-entregas' }" v-if="isInRole('guia-bancada')">Acompanhamentos</router-link>
                                <router-link :to="{ name: 'emissao-bancada' }" v-if="isInRole('guia-bancada')">Mapa de Trabalho</router-link>
                                <router-link :to="{ name: 'progresso-bancada' }" v-if="isInRole('guia-bancada')">Progresso por Mapa de Trabalho</router-link>
                                <router-link :to="{ name: 'progresso-setor' }" v-if="isInRole('guia-bancada')">Progresso por setor</router-link>
                                <router-link :to="{ name: 'progresso-amostra' }" v-if="isInRole('guia-bancada')">Progresso por amostra</router-link>
                                <hr style="margin: 0 !important; background-color: #e0e0e0; border: none; display: block; height: 2px;">
                                <a :href="'/Analise/DigitarPorLotes'" v-if="isInRole('digitacao-lotes')">Digitação em lote</a>
                                <router-link :to="{ name: 'lote-resultados' }" v-if="isInRole('resultado-visualizar')">Emissão em lote</router-link>
                                <hr style="margin: 0 !important; background-color: #e0e0e0; border: none; display: block; height: 2px;">
                                <router-link :to="{ name: 'amostraIncidencia' }" v-if="isInRole('guia-bancada')">Amostras com Incidências</router-link>
                                <router-link :to="{ name: 'soroteca-armazenar' }" v-if="isInRole('soroteca')">Armazenar amostras</router-link>
                                <router-link :to="{ name: 'soroteca-localizar' }" v-if="isInRole('soroteca')">Soroteca</router-link>
                                <hr style="margin: 0 !important; background-color: #e0e0e0; border: none; display: block; height: 2px;">
                                <router-link :to="{ name: 'exames-bloqueados', query: { unidadeId: unidadeId} }" v-if="isInRole('guia-bancada')">Exames bloqueados</router-link>
                                <router-link :to="{ name: 'entregaresultados' }" v-if="isInRole('entrega-resultados')">Entrega de resultados</router-link>
                                <hr style="margin: 0 !important; background-color: #e0e0e0; border: none; display: block; height: 2px;">
                                <router-link :to="{ name: 'estudos' }" v-if="isInRole('guia-bancada')">Estudos</router-link>
                            </div>
                        </li>




                        <li class="colorSides sidebar-item" v-if="isInRole('menu-financeiro')">
                            <a @click="handleClickFinanceiro" @mouseover="handleMouseover" class="dropdown-trigger" style="display: flex; align-items: center;">
                                <img src="../assets/icon/financeiro.svg" width="22" height="22">
                                &nbsp;&nbsp;&nbsp;&nbsp;{{$t('SIDEBAR.FINANCEIRO')}} <span style="margin-left: auto;"><b-icon :icon="isMobile ? (isSubmenuVisibleFinanceiro ? 'chevron-up' : 'chevron-down') : 'chevron-right'" size="is-big"></b-icon></span>
                            </a>
                            <div :class="{'sidebar-submenu': !isMobile}" v-show="isSubmenuVisibleFinanceiro">
                                <router-link :to="{ name: 'listarpagar' }" v-if="isInRole('pagar-alterar') || isInRole('pagar-consultar')">Contas a pagar</router-link>
                                <router-link :to="{ name: 'lista-parcela-lote-pagar' }" v-if="isInRole('pagar-parcela-lote-alterar') || isInRole('pagar-parcela-lote-consultar')">Lote de Contas a pagar</router-link>
                                <hr style="margin: 0 !important; background-color: #e0e0e0; border: none; display: block; height: 2px;">
                                <router-link :to="{ name: 'comparacaoCustos' }" v-if="isInRole('tabelapreço-comparar-custo')">Tabela de Custos</router-link>
                                <router-link :to="{ name: 'extrato-conciliacao' }" v-if="isInRole('conciliacaoBancaria-conciliar-consultar')">Conciliação bancária</router-link>
                                <router-link :to="{ name: 'emitir-dre' }">DRE</router-link>
                                <router-link :to="{ name: 'fluxo-caixa' }" v-if="isInRole('fluxodecaixa') || isInRole('receber-relacaoDeDebito') || isInRole('fechamentodecaixa')">Fluxo de caixa</router-link>
                                <router-link :to="{ name: 'repasses-medicos' }" v-if="isInRole('relatorio-repasse-medico')">Repasse médico</router-link>
                            </div>
                        </li>

                        <li class="colorSides sidebar-item" v-if="isInRole('receber-consultar')">
                            <a @click="handleClickFaturamento" @mouseover="handleMouseover" class="dropdown-trigger" style="display: flex; align-items: center;">
                                <img src="../assets/icon/faturamento.svg" width="22" height="22">
                                &nbsp;&nbsp;&nbsp;&nbsp;{{$t('SIDEBAR.FATURAMENTO')}} <span style="margin-left: auto;"><b-icon :icon="isMobile ? (isSubmenuVisibleFaturamento ? 'chevron-up' : 'chevron-down') : 'chevron-right'" size="is-big"></b-icon></span>
                            </a>
                            <div :class="{'sidebar-submenu': !isMobile}" v-show="isSubmenuVisibleFaturamento">
                                <router-link :to="{ name: 'receber-faturar' }" v-if="isInRole('receber-alterar') || isInRole('receber-consultar')">Faturar</router-link>
                                <router-link :to="{ name: 'lista-faturas' }" v-if="isInRole('receber-alterar') || isInRole('receber-consultar')">Faturas</router-link>
                                <router-link :to="{ name: 'lista-parcela-lote' }" v-if="isInRole('receber-parcela-lote-alterar') || isInRole('receber-parcela-lote-consultar')">Lote de Faturas</router-link>
                            </div>
                        </li>

                        <li class="colorSides sidebar-item" v-if="isInRole('menu-estoque')">
                            <a @click="handleClickEstoque" @mouseover="handleMouseover" class="dropdown-trigger" style="display: flex; align-items: center;">
                                <img src="../assets/icon/estoque.svg" width="22" height="22">
                                &nbsp;&nbsp;&nbsp;&nbsp;{{$t('SIDEBAR.ESTOQUE')}} <span style="margin-left: auto;"><b-icon :icon="isMobile ? (isSubmenuVisibleEstoque ? 'chevron-up' : 'chevron-down') : 'chevron-right'" size="is-big"></b-icon></span>
                            </a>
                            <div :class="{'sidebar-submenu': !isMobile}" v-show="isSubmenuVisibleEstoque">
                                <a :href="'/Estoque/ListarPedidos'" v-if="isInRole('pedido-alterar') || isInRole('pedido-consultar') || isInRole('pedido-incluir')">Entrada de materiais</a>
                                <a :href="'/Estoque/ListarEstoqueMovimentacao'" v-if="isInRole('estoqueMovimentacao-alterar') || isInRole('estoqueMovimentacao-consultar')">Movimentação</a>
                                <a :href="'/Estoque/ListarPedidosInternos'" v-if="isInRole('pedidointerno-alterar') || isInRole('pedidointerno-consultar') || isInRole('pedidointerno-incluir')">Solicitação</a>
                            </div>
                        </li>


                        <li class="colorSides sidebar-item" v-if="isInRole('menu-relatorios')">
                            <a href="/Rel/index" class="dropdown-trigger" style="display: flex; align-items: center;">
                                <img src="../assets/icon/relatorio.svg" width="22" height="22">
                                &nbsp;&nbsp;&nbsp;&nbsp;{{$t('SIDEBAR.RELATORIO')}}
                            </a>
                        </li>



                        <li class="colorSides sidebar-item" v-if="isInRole('menu-plugins')">
                            <a href="/Plugins/index" class="dropdown-trigger" style="display: flex; align-items: center;">
                                <img src="../assets/icon/plugin.svg" width="22" height="22">
                                &nbsp;&nbsp;&nbsp;&nbsp;{{$t('SIDEBAR.PLUGINS')}}
                            </a>
                        </li>

                        <li class="colorSides sidebar-item" v-if="isInRole('menu-manutencao')">
                            <a @click="handleClickManutencao" @mouseover="handleMouseover" class="dropdown-trigger" style="display: flex; align-items: center;">
                                <img src="../assets/icon/config3.svg" width="22" height="22">
                                &nbsp;&nbsp;&nbsp;&nbsp;{{$t('SIDEBAR.MANUTENCAO')}} <span style="margin-left: auto;"><b-icon :icon="isMobile ? (isSubmenuVisibleManutencao ? 'chevron-up' : 'chevron-down') : 'chevron-right'" size="is-big"></b-icon></span>
                            </a>
                            <div :class="{'sidebar-submenu': !isMobile}" v-show="isSubmenuVisibleManutencao" style="top: -500%">
                                <router-link :to="{ name: 'config-agenda' }">Agenda</router-link>
                                <router-link :to="{ name: 'config-empresa' }">Empresa</router-link>
                                <router-link :to="{ name: 'equipamentos' }" v-if="isInRole('menu-interface')">Equipamentos</router-link>
                                <router-link :to="{ name: 'config-estoque'}">Estoque</router-link>
                                <router-link :to="{ name: 'config-exames'}">Exames</router-link>
                                <router-link :to="{ name: 'config-faturamento'}">Faturamento</router-link>
                                <router-link :to="{ name: 'config-financeiro' }">Financeiro</router-link>
                                <a href="/Integracao/index" v-if="isInRole('menu-integracao')">Integração</a>
                                <router-link :to="{ name: 'config-pacientes'}" v-if="config.laboratorioVeterinario">Pacientes</router-link>
                                <router-link :to="{ name: 'config-usuarios' }">Usuários</router-link>

                            </div>
                        </li>


                    </ul>
                </div>


                <hr class="dropdown-divider">

                <div class="is-flex">


                    <b-tooltip label="Impressão" type="is-dark" position="is-bottom">
                        <a class="is-paddingless mx-2" @click="TOGGLE_MODAL_IMPRESSAO">
                            <img src="../assets/icon/impressora.svg" width="20" height="20">
                        </a>
                    </b-tooltip>

                    <div class="paddingicon"></div>

                    <b-modal :active.sync="modalImpressaoIsOpened" has-modal-card trap-focus aria-role="dialog" aria-modal>
                        <modal-impressao></modal-impressao>
                    </b-modal>

                    <b-tooltip label="Notificações" type="is-dark" position="is-bottom">
                        <a class="is-paddingless mx-2" @click="TOGGLE_MODAL_NOTIFICACAO">
                            <img src="../assets/icon/sino.svg" width="20" height="20">
                            <b-tag v-if="notificationsCount > 0" type="is-info tag-notify" rounded>{{ notificationsCount }}</b-tag>
                        </a>
                    </b-tooltip>

                    <div class="paddingicon"></div>

                    <b-modal :active.sync="modalNotificacaoIsOpened" has-modal-card trap-focus aria-role="dialog" aria-modal>
                        <modal-notificacao></modal-notificacao>
                    </b-modal>

                    <b-tooltip label="Configuração do Usuário" type="is-dark" position="is-bottom">
                        <a class="is-paddingless mx-2" @click="TOGGLE_MODAL_USUARIO">
                            <img src="../assets/icon/user.svg" width="20" height="20">
                        </a>
                    </b-tooltip>

                    <div class="paddingicon"></div>

                    <b-modal :active.sync="modalUsuarioIsOpened" has-modal-card trap-focus aria-role="dialog" aria-modal>
                        <modal-usuario></modal-usuario>
                    </b-modal>

                    <b-tooltip label="Sair" type="is-dark" position="is-bottom">
                        <a class="is-paddingless mx-2" @click="logout">
                            <img src="../assets/icon/sair.svg" width="20" height="20">

                        </a>
                    </b-tooltip>
                </div>

                <hr class="dropdown-divider">
            
                <div v-if="this.unidadeNome != null">
                    <h4 class="has-text-weight-bold title is-6 has-text-centered is-marginless">
                        {{ usuario.nomeCadastro }}
                    </h4>
                    <p class=" has-text-centered is-marginless">{{ usuario.nivel }}</p>
                    <hr class="dropdown-divider">
                    <b-field v-if="isInRole('alterar-unidade-atual') && alterandoUnidade"
                             label="Unidade"
                             label-position="on-border">
                        <b-select size="is-big" v-model="unidadeId"
                                  expanded>
                            <option v-for="(item, index) in unidades"
                                    v-bind:key="index"
                                    :value="item.id">
                                {{item.nome}}
                            </option>
                        </b-select>
                    </b-field>
                    <p v-else class="has-text-weight-bold is-size-6 has-text-centered">
                        Unidade: <b class="has-text-weight-light is-size-6 has-text-centered">{{unidadeNome}}</b>
                        <b-tooltip :label="$t('Alterar unidade')" placement="bottom" position="is-bottom" type="is-dark">
                            <a v-if="isInRole('alterar-unidade-atual') && usuario.unidadeId ==null" href="#" class="has-text-grey" @click="alterandoUnidade = true">
                                &nbsp; <b-icon icon="pencil" size="is-small" type="is-dark"></b-icon>
                            </a>
                        </b-tooltip>
                    </p>
                </div>
                <div v-else>
                    <h4 class="has-text-weight-bold title is-6 has-text-centered is-marginless">
                        {{ usuario.nomeCadastro }}
                    </h4>
                    <p class="has-text-centered is-marginless">{{ usuario.nivel }}</p>
                    <hr class="dropdown-divider">
                    <b-field v-if="isInRole('alterar-unidade-atual')"
                             label="Unidade"
                             label-position="on-border">
                        <b-select size="is-big" v-model="unidadeId" expanded>
                            <option v-for="(item, index) in unidades" :key="index" :value="item.id">
                                {{ item.nome }}
                            </option>
                        </b-select>
                    </b-field>
                    <div class="notification is-danger is-light">
                        Informe a unidade de trabalho.
                    </div>
                </div>
            </aside>
            <div v-if="statusFinanceiro && statusFinanceiro.mensagem && statusFinanceiro.status == 2">
                <div class="notification is-danger navbar-item">
                    <span>{{statusFinanceiro.mensagem}}</span>
                </div>
            </div>
        </div>
    </transition>    
</template>

<script>
/* eslint-disable */
    import { mapActions, mapState, mapGetters } from 'vuex'
    import { mapMutations } from 'vuex'
    import modalImpressao from '@/components/modalImpressao.vue'
    import modalNotificacao from '@/components/modalNotificacao.vue'
    import modalUsuario from '@/components/modalUsuario'
    import modalFilaAtendimento from '@/components/atendimento/modalFilaAtendimento.vue'

    export default {
        data() {
            return {
                isMobile: false,
                isSubmenuVisibleAgenda: false,
                isSubmenuVisibleAtendimento: false,
                isSubmenuVisibleEstoque: false,
                isSubmenuVisibleColeta: false,
                isSubmenuVisibleAnalise: false,
                isSubmenuVisibleFinanceiro: false,
                isSubmenuVisibleFaturamento: false,
                isSubmenuVisibleManutencao: false,
                alterandoUnidade: false,
                abrirGuiaId: null,
                abrirGuiaReferencia: null,
                activeField: 'field1',
                abrirGuiaAmostra: null,
                abrirGuiaAmostraReferencia: null,
                statusFinanceiro:{
                    status: null,
                    mensagem: null
                },
            }
        },
        mounted() {
            this.getStatusFinanceiro();
            this.handleResize();
            window.addEventListener('resize', this.handleResize);
            this.checkWindowSize();
            window.addEventListener('resize', this.checkWindowSize);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.handleResize);
            window.removeEventListener('resize', this.checkWindowSize);
        },
        components: {
            modalImpressao,
            modalNotificacao,
            modalUsuario
        },
        computed: {
            idleRemainingTime() {
                const totalSeconds = (this.$store.state.config.logoffMiliseconds - this.$store.state.idle.time) / 1000;

                const minutes = Math.floor(totalSeconds / 60);
                const seconds = Math.floor(totalSeconds % 3600 % 60);

                return minutes + ":" +
                    (seconds < 10 ? "0" : "") + seconds;
            },
            idleProgressBarValue() {
                const value = ((this.$store.state.config.logoffMiliseconds - this.$store.state.idle.time) / this.$store.state.config.logoffMiliseconds) * 100;
                return isNaN(value)
                    ? 100
                    : value;
            },
            idleProgressBarType() {
                return this.idleProgressBarValue > 66
                    ? "is-info"
                    : this.idleProgressBarValue > 33
                        ? "is-warning"
                        : "is-danger";
            },
            notificationsCount() {
                return this.$store.getters['notificacao/Count']
            },
            isDashboard(){
                return this.$route.name == 'exames-entregas' || this.$route.name == 'progresso-setor'
            },
            modalImpressaoIsOpened: {
                get() {
                    return this.$store.state.componentes.modalImpressaoIsOpened;
                },
                set() {
                    return this.$store.commit('componentes/TOGGLE_MODAL_IMPRESSAO')
                }
            },
            modalNotificacaoIsOpened: {
                get() {
                    return this.$store.state.componentes.modalNotificacaoIsOpened;
                },
                set() {
                    return this.$store.commit('componentes/TOGGLE_MODAL_NOTIFICACAO')
                }
            },
            modalUsuarioIsOpened: {
                get() {
                    return this.$store.state.componentes.modalUsuarioIsOpened;
                },
                set() {
                    return this.$store.commit('componentes/TOGGLE_MODAL_USUARIO')
                }
            },
            ...mapState([
                'config',
                'usuario',
                'unidadeId',
                'unidades'
            ]),
            ...mapState('componentes', [
                'sidebarIsOpened'
            ]),
            ...mapState('senhaAtendimento', [
                'atendendo'
            ]),
            ...mapGetters([
                'isInRole'
            ]),
            unidadeId: {
                get() {
                    return this.$store.state.unidadeId;
                },
                set(value) {
                    this.alterandoUnidade = false;
                    this.$store.commit('SET_UNIDADE', value);
                    if (this.unidadeNome != null) {
                        window.location.reload();
                    }
                }
            },
            unidadeNome() {
                const unidade = this.unidades?.find(x => x.id == this.unidadeId);
                return unidade?.nome;
            },
        },
        methods: {
            abrirSenhasAtendimento() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalFilaAtendimento,
                    hasModalCard: true,
                    trapFocus: true
                })
            },
            checkWindowSize() {
                this.isMobile = window.innerWidth <= 768; // Defina o breakpoint de mobile conforme necessário
            },
            showField(fieldId) {
                this.activeField = fieldId;
            },
            handleClickAgenda(event) {
            if (window.matchMedia("(max-width: 768px)").matches) {
                event.preventDefault();
                this.isSubmenuVisibleAgenda = !this.isSubmenuVisibleAgenda;
            }
            },
            handleClickColeta(event) {
            if (window.matchMedia("(max-width: 768px)").matches) {
                event.preventDefault();
                this.isSubmenuVisibleColeta = !this.isSubmenuVisibleColeta;
            }
            },
            handleClickAtendimento(event) {
            if (window.matchMedia("(max-width: 768px)").matches) {
                event.preventDefault();
                this.isSubmenuVisibleAtendimento = !this.isSubmenuVisibleAtendimento;
            }
            },
            handleClickEstoque(event) {
            if (window.matchMedia("(max-width: 768px)").matches) {
                event.preventDefault();
                this.isSubmenuVisibleEstoque = !this.isSubmenuVisibleEstoque;
            }
            },
            handleClickAnalise(event) {
            if (window.matchMedia("(max-width: 768px)").matches) {
                event.preventDefault();
                this.isSubmenuVisibleAnalise = !this.isSubmenuVisibleAnalise;
            } 
            },
            handleResize() {
                this.isMobile = window.innerWidth <= 768;
            },
            handleClickFinanceiro(event) {
            if (window.matchMedia("(max-width: 768px)").matches) {
                event.preventDefault();
                this.isSubmenuVisibleFinanceiro = !this.isSubmenuVisibleFinanceiro;
            }
            },
            handleClickFaturamento(event) {
            if (window.matchMedia("(max-width: 768px)").matches) {
                event.preventDefault();
                this.isSubmenuVisibleFaturamento = !this.isSubmenuVisibleFaturamento;
            } 
            },
            handleClickManutencao(event) {
            if (window.matchMedia("(max-width: 768px)").matches) {
                event.preventDefault();
                this.isSubmenuVisibleManutencao = !this.isSubmenuVisibleManutencao;
            } 
            },
            handleMouseover() {
            if (!window.matchMedia("(max-width: 768px)").matches) {
                this.isSubmenuVisibleAgenda = true;
                this.isSubmenuVisibleAtendimento = true;
                this.isSubmenuVisibleEstoque = true;
                this.isSubmenuVisibleColeta = true;
                this.isSubmenuVisibleAnalise = true;
                this.isSubmenuVisibleFinanceiro = true;
                this.isSubmenuVisibleFaturamento = true;
                this.isSubmenuVisibleManutencao = true;
            }
            },
            ...mapActions([
                'logout'
            ]),
            ...mapMutations('componentes', [
                'HANDLE_RESIZE',
                'SET_SIDEBAR',
                'TOGGLE_MODAL_IMPRESSAO',
                'TOGGLE_MODAL_NOTIFICACAO',
                'TOGGLE_MODAL_USUARIO',
            ]),
            abrirGuia() {            
    
                if (!this.abrirGuiaId) {
                     this.$buefy.snackbar.open({
                         duration: 2000,
                         message: 'Favor digite o código da guia',
                         type: 'is-danger',
                         position: 'is-top-left',
                         queue: false
                     })
                     return
                 }

                //verifica se existe a guia
                this.$http.get('/api/atendimento/edit?id=' + this.abrirGuiaId)
                    .then(res => {
                        this.$router.push({
                            name: 'guia', params: {
                                id: this.abrirGuiaId
                            }
                        })
                        this.HANDLE_RESIZE()
                    })
                    .catch(e => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Guia ' + this.abrirGuiaId + ' não encontrada',
                            type: 'is-danger',
                            position: 'is-top-left',
                            queue: false
                        })
                    })
            },
            abrirGuiaRef() {    
                
                if (!this.abrirGuiaReferencia) {
                     this.$buefy.snackbar.open({
                         duration: 2000,
                         message: 'Favor digite a referência da guia',
                         type: 'is-danger',
                         position: 'is-top-left',
                         queue: false
                     })
                     return
                 }

                //verifica se existe a guia
                this.$http.get('/api/atendimento/AbrirGuiaReferencia?referencia=' + this.abrirGuiaReferencia)
                    .then(res => {
                        if(res.data != null && res.data.length == 1){
                            this.$router.push({
                                name: 'referencia', params: {
                                    referencia: this.abrirGuiaReferencia
                                }
                            })
                            this.HANDLE_RESIZE()
                        } else if (res.data != null && res.data.length > 1){
                            this.$router.push({
                                name: 'guia-list', params: {
                                    referencia: this.abrirGuiaReferencia
                                }
                            })
                        }
                    })
                    .catch(e => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Guia com referência ' + this.abrirGuiaReferencia + ' não encontrada',
                            type: 'is-danger',
                            position: 'is-top-left',
                            queue: false
                        })
                    })
            },
            abrirGuiaAmo() {       
                
                if (!this.abrirGuiaAmostra) {
                    this.$buefy.snackbar.open({
                        duration: 2000,
                        message: 'Favor digite o código da amostra',
                        type: 'is-danger',
                        position: 'is-top-left',
                        queue: false
                    })
                    return
                }

                //verifica se existe a guia
                this.$http.get('/api/atendimento/AbrirGuia?codigoDeBarras=' + this.abrirGuiaAmostra)
                    .then(res => {
                        if(res.data != null && res.data.length == 1){
                            this.$router.push({
                                name: 'amostra', params: {
                                    amostra: this.abrirGuiaAmostra
                                }
                            })
                            this.HANDLE_RESIZE()
                        } else if (res.data != null && res.data.length > 1){
                            this.$router.push({
                                name: 'guia-list', params: {
                                    amostra: this.abrirGuiaAmostra
                                }
                            })
                        }
                    })
                    .catch(e => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Amostra ' + this.abrirGuiaAmostra + ' não encontrada',
                            type: 'is-danger',
                            position: 'is-top-left',
                            queue: false
                        })
                    })
            },
            abrirGuiaAmoRef() {     
                
                if (!this.abrirGuiaAmostraReferencia) {
                     this.$buefy.snackbar.open({
                         duration: 2000,
                         message: 'Favor digite a referência da amostra',
                         type: 'is-danger',
                         position: 'is-top-left',
                         queue: false
                     })
                     return
                 }

                this.$http.get('/api/atendimento/AbrirGuiaAmostraReferencia?amostraReferencia=' + this.abrirGuiaAmostraReferencia)
                    .then(res => {
                        if(res.data != null && res.data.length == 1){
                            this.$router.push({
                                name: 'amostrareferencia', params: {
                                    amostraReferencia: this.abrirGuiaAmostraReferencia
                                }
                            })
                            this.HANDLE_RESIZE()
                        } else if (res.data != null && res.data.length > 1){
                            this.$router.push({
                                name: 'guia-list', params: {
                                    amostraReferencia: this.abrirGuiaAmostraReferencia
                                }
                            })
                        }
                    })
                    .catch(e => {
                        this.$buefy.snackbar.open({
                            duration: 2000,
                            message: 'Amostra com a referência ' + this.abrirGuiaAmostraReferencia + ' não encontrada',
                            type: 'is-danger',
                            position: 'is-top-left',
                            queue: false
                        })
                    })           
            },
            removerAtendendo() {
                this.$store.commit('senhaAtendimento/SET_ATENDENDO', undefined)
            },
            getStatusFinanceiro() {
                this.$http.get('/api/atendimento/StatusFinanceiro')
                    .then(s => {
                        this.statusFinanceiro = s.data
                    })
            },
        },
    }
</script>

<style scoped>
    .labelxs{
        font-size: 14px;
        font-weight: 600;
        margin-left: 2%;
    }
    .inputch{
        border-color: transparent;
        box-shadow: none;
    }

    img{
        max-width: none;
    }

    img, video{
        max-width: none;
    }

</style>