import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
import { mapGetters, mapState } from 'vuex';
import store from '@/store';

export default {
    computed: {
        ...mapState([
            'usuario'
        ]),
        ...mapGetters([
            'isUser'
        ])
    },
    install(Vue) {
        const notificacaoHub = new HubConnectionBuilder()
            .withUrl('/notificacaoHub')
            .configureLogging(LogLevel.Information)
            .build();

        Vue.prototype.$notificacaoHub = notificacaoHub

        Vue.prototype.iniciarHub = function () {
            Vue.prototype.$notificacaoHub.start()
                .then(function () {
                    window.localStorage.setItem('printing_lock', '');
                })
                .catch(function (error) {
                    console.error(error.message)
                });
        }

        // reconecta automaticamente
        notificacaoHub.onclose(Vue.prototype.iniciarHub)

        notificacaoHub.on("receberMsg", function (id, from, type, body) {
            if(type.includes("toast")){
                Vue.prototype.$buefy.toast.open({
                    duration: 10000,
                    message: body,
                    type: 'is-warning'
                })
            }else{
                store.commit("notificacao/ADD", {
                    id: id,
                    de: from,
                    tipo: type,
                    datahora: Date.now(),
                    mensagem: body                    
                });                

            }

        });

        notificacaoHub.on("RemoteAjaxAgendamento", function (domId, html, userref) {
            if (store.getters.isUser(userref)) {

                store.commit("SET_AGENDAMENTOTAREFACONFIG", html);

                Vue.http.post('/api/agendamentos/recebeuajax', {
                    connId: userref
                })
                    .then(res => {
                        this.model = res.data;
                    })

            } else {
                console.log("Alvo DOM vazio.");
            }
        });

        function trataImpressao(descricao, impressora, linhasDeCodigo, jobId, dados) {

            const printingLock = window.localStorage.getItem("printing_lock");
            if (printingLock !== null && printingLock !== '') {
                console.log('processamento ' + printingLock + ' em execucao. Adiando em 1000ms...');
                setTimeout(trataImpressao, 1000, descricao, impressora, linhasDeCodigo, jobId, dados);
            } else {
                window.localStorage.setItem("printing_lock", jobId);

                const printLogs = window.localStorage.getItem('printlogs');

                console.log('recebeu trabalho de impressao: ' + jobId);
                console.log(printLogs);

                if (printLogs !== null) {
                    const impressoesLogadas = printLogs.split(',');
                    //se esse job ja foi tratado, ignora
                    if (impressoesLogadas.indexOf(jobId) > -1) {
                        console.log('ignorando (já processado) trabalho de impressao: ' + jobId);
                        return false;
                    }
                }

                store.commit('laborlisWebsocket/CHECK_IMPRESSORA', impressora, { root: true })

                console.log(store.state.laborlisWebsocket.impressoras)

                const filaImpressora = store.state.laborlisWebsocket.filasImpressoras.filter(x => x.nome === impressora)[0]

                //let impressaoEmLote = $('#printProgresso').length > 0;
                //console.log('impressao em lotes? ' + impressaoEmLote);

                if (filaImpressora != null && filaImpressora.printAuto) {
                    if (linhasDeCodigo.length === 1) {
                        const linhas = linhasDeCodigo[0].substring(1, linhasDeCodigo[0].length - 1);
                        let linhasArray = linhas.split('\\n').join('\n');
                        if (linhasArray.startsWith("XA")) {
                            linhasArray = "^" + linhasArray;
                        }
                        while (linhasArray.indexOf("\\") !== -1) {
                            linhasArray = linhasArray.replace("\\", "");
                        }
                        Vue.prototype.$laborwebsocket.print(impressora, filaImpressora.printId, linhasArray, dados);
                    }
                    else {
                        console.log(linhasDeCodigo);
                        Vue.prototype.$laborwebsocket.print(impressora, filaImpressora.printId, linhasDeCodigo.join('\n'), dados);
                    }

                } else {

                    store.dispatch('laborlisWebsocket/getFilasImpressoras')

                    const printJob = {
                        descricao: descricao,
                        impressora: impressora,
                        linhasDeCodigo: linhasDeCodigo,
                        datahora: Date.now(),
                        dados: dados
                    };

                    store.commit('laborlisWebsocket/ADD_PRINT_JOB', {
                        impressora,
                        printJob
                    }, { root: true })

                    store.commit('componentes/OPEN_MODAL_IMPRESSAO')

                    //if (impressaoEmLote) {
                    //    let posicaoAtual = $('#processIndicator')[0].innerText;
                    //    let posicaoFinal = $('#printMeta')[0].innerText;

                    //    if (posicaoAtual === '0') {
                    //        $('#printProgresso').show();
                    //        $('#btnImprimirEtiquetas').attr('disabled', 'disabled');
                    //    }

                    //    let novaPosicao = parseInt(posicaoAtual) + 1;
                    //    $('#processIndicator')[0].innerText = novaPosicao.toString();

                    //    if (novaPosicao.toString() === posicaoFinal) {
                    //        $('#printProgresso').remove();
                    //        atualizaPrintJobCount();
                    //        $('#impressaoModal').modal('show');
                    //        $('#btnImprimirEtiquetas').removeAttr('disabled');
                    //    }

                    //} else {
                    //    atualizaPrintJobCount();
                    //    $('#impressaoModal').modal('show');
                    //}
                }

                if (printLogs !== null) {
                    const impressoesLogadass = printLogs.split(',');
                    impressoesLogadass.push(jobId);

                    //remove item mais antigo da lista
                    if (impressoesLogadass.length > 50) {
                        impressoesLogadass.splice(0, 1);
                    }

                    window.localStorage.setItem('printlogs', impressoesLogadass);
                } else {
                    window.localStorage.setItem('printlogs', jobId);
                }

                console.log('printlogs apos execucao:');
                console.log(window.localStorage.getItem('printlogs'));
                window.localStorage.setItem("printing_lock", "");
            }
        }

        notificacaoHub.on("ImpressaoRaw", function (descricao, impressora, linhasDeCodigo, jobId, dados) {
            const delayExecucao = Math.ceil(Math.random() * 100);
            console.log("delay de impressao: " + delayExecucao);
            setTimeout(trataImpressao, delayExecucao, descricao, impressora, linhasDeCodigo, jobId, dados);
        });

        notificacaoHub.on("SenhaAtendimentoChamada", function (id, unidadeId, sequencia, situacao, tipoId, tipo, guiche) {
            store.commit("senhaAtendimento/CHAMAR", {
                id: id,
                unidadeId: unidadeId,
                sequencia: sequencia,
                situacao: situacao,
                tipoId: tipoId,
                tipo: tipo,
                guiche: guiche,
                datahora: Date.now()
            })
        })

        notificacaoHub.on("SenhaAtendimentoSolicitada", function (tipo, sequencia) {
            Vue.prototype.$buefy.toast.open({
                duration: 5000,
                message: '<span class="is-size-3">Agrademos a preferência! Aguarde a sua chamada pela senha <b>' + tipo + ' ' + sequencia + '</b>.</span>',
                position: 'is-bottom'
            })
        })

        notificacaoHub.on("SenhaAtendimentoRemover", function (id) {
            store.commit("senhaAtendimento/APAGAR", id)
        })

        notificacaoHub.on("SenhaAtendimentoLista", function (lista) {
            store.commit("senhaAtendimento/SET_LISTA", lista)
        })

        function converBase64toBlob(content, contentType) {
            contentType = contentType || '';
            const sliceSize = 512;
            const byteCharacters = window.atob(content);
            const byteArrays = [
            ];
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            const blob = new Blob(byteArrays, {
                type: contentType
            });
            return blob;
        }

        notificacaoHub.on("DownloadFile", function (arquivoNome, mimetype, base64Data) {
            
            const contentType = mimetype;

            const blob = converBase64toBlob(base64Data, contentType);
            const blobURL = URL.createObjectURL(blob);

            const link = document.createElement('a');
            document.body.appendChild(link);
            link.href = blobURL;
            link.download = arquivoNome;
            link.click();
            window.URL.revokeObjectURL(blobURL);
            link.remove();
        });

    }
}
