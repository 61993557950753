<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>

        <b-message
            v-for="(erro, index) in erros"
            v-bind:key="index"
            title="Erro"
            type="is-danger"
            :aria-close-label="$t('INCLUIRLOTEAMOSTRAS.FECHARMENSAGEM')"
            >
            {{ erro }}
        </b-message>

        <b-table v-if="amostras.length > 0" :data="amostras"
                 :loading="loading"
                 striped
                 hoverable
                :checked-rows.sync="checkedRows"
                checkable
                :checkbox-position="checkboxPosition">

            <template >
                <b-table-column  v-slot="props" field="Contador" label="">
                    <span class="tag is-pulled-right is-dark">{{ (props.index + 1) }}</span>
                </b-table-column>

                <b-table-column  v-slot="props" field="Amostra" :label="$t('INCLUIRLOTEAMOSTRAS.AMOSTRA')">
                    {{ props.row.amostraCodigoDeBarras }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Paciente" :label="$t('SISTEMA.PACIENTE')">
                    {{ props.row.pacienteNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Material" :label="$t('INCLUIRLOTEAMOSTRAS.MATERIAL')">
                    {{ props.row.materialNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Conservante" :label="$t('INCLUIRLOTEAMOSTRAS.CONSERVANTE')">
                    {{ props.row.conservanteNome }}
                </b-table-column>

                <b-table-column  v-slot="props" field="Setor" :label="$t('INCLUIRLOTEAMOSTRAS.SETOR')">
                    {{ props.row.setorNome }}
                    <span class="tag is-pulled-right" :style="'background-color: #' + props.row.setorCor + '; font-size: 10pt;'">&nbsp;</span>
                </b-table-column>

                <b-table-column   v-slot="props" field="Exames" :label="$t('INCLUIRLOTEAMOSTRAS.EXAMES')">
                    <span v-for="(item, index) in props.row.examesApelidos" v-bind:key="index" class="tag is-default">
                        {{item}}<span> </span>
                    </span>
                </b-table-column>

                <b-table-column  v-slot="props" field="Acondicionamento" label="">
                    <span v-if="props.row.amostraAcondicionamento == 3" class="tag is-pulled-right is-light" :title="$t('INCLUIRLOTEAMOSTRAS.ACONDICIONAMENTOCONGELADA')">C</span>
                    <span v-if="props.row.amostraAcondicionamento == 2" class="tag is-pulled-right is-link" :title="$t('INCLUIRLOTEAMOSTRAS.ACONDICIONAMENTOREFRIGERADA')">R</span>
                    <span v-if="props.row.amostraAcondicionamento == 1" class="tag is-pulled-right is-primary" :title="$t('INCLUIRLOTEAMOSTRAS.ACONDICIONAMENTOAMBIENTE')">A</span>
                </b-table-column>
            </template>
        </b-table>
        <br>
        <div class='box'>
            <article class="level">
                <div class="level-left">
                    <div class="is-light">
                        <form @submit.prevent="incluirAmostra" method="get">
                            <label><strong>{{$t('INCLUIRLOTEAMOSTRAS.CODIGOBARRAS')}}</strong></label>
                            <div class="field has-addons">
                                <div class="control">
                                    <input type="text" v-model="amostraCodigoDeBarras" class="input" ref="amostraCodigoDeBarras"  id="amostraCodigoDeBarras"/>
                                </div>
                                <div class="control">
                                    <a class="button is-success" @click="incluirAmostra();">
                                        {{$t('INCLUIRLOTEAMOSTRAS.INCLUIR')}}
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="column">
                        <label><strong>{{$t('INCLUIRLOTEAMOSTRAS.ACONDICIONAMENTO')}}</strong></label>
                        <b-select expanded :disabled="amostraBipada" v-model="acondicionamento">
                            <option :value="0"></option>
                            <option :value="1">{{ $t('INCLUIRLOTEAMOSTRAS.AMBIENTE') }}</option>
                            <option :value="2">{{ $t('INCLUIRLOTEAMOSTRAS.REFRIGERADO') }}</option>
                            <option :value="3">{{ $t('INCLUIRLOTEAMOSTRAS.CONGELADO') }}</option>
                        </b-select>
                    </div>
                    <div class="column">
                        <label><strong>{{$t('INCLUIRLOTEAMOSTRAS.DESCRICAO')}}</strong></label>
                        <input type="text" v-model="descricao" class="input" maxlength="100" />
                    </div>
                </div>
                <div class="column">
                    <template v-if="config.loteAmostraOrigemDestinoObrigatoria">
                        <ValidationProvider rules="required" name="Temperatura" ref="temperaturaValidation" v-slot="{ errors, valid }">
                            <b-field :label="$t('INCLUIRLOTEAMOSTRAS.TEMPERATURA')"
                                     :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                     :message="errors">
                                <input type="number" v-model="temperatura" class="input" ref="temperaturaInput"
                                      :class="{ 'is-danger': errors[0], 'is-success': valid }"/>
                            </b-field>
                        </ValidationProvider>
                    </template>
                    <template v-else>
                        <b-field :label="$t('INCLUIRLOTEAMOSTRAS.TEMPERATURA')">
                            <input type="number" v-model="temperatura" class="input"/>
                        </b-field>
                    </template>
                </div>
                <div class="column is-2">
                    <template v-if="config.loteAmostraOrigemDestinoObrigatoria">
                        <ValidationProvider rules="required" name="Local de Origem" ref="localOrigemValidation" v-slot="{ errors, valid }">
                            <b-field :label="$t('INCLUIRLOTEAMOSTRAS.LOCALDEORIGEM')"
                                     :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                     :message="errors">
                                <b-select v-model="localOrigemId" ref="localOrigemSelect">
                                    <option :value="null"></option>
                                    <template v-for="(item, index) in locaisOrigem">
                                        <option v-bind:key="index" :value="item.id">
                                            {{item.nome}}
                                        </option>
                                    </template>
                                </b-select>
                            </b-field>
                        </ValidationProvider>
                    </template>
                    <template v-else>
                        <b-field :label="$t('INCLUIRLOTEAMOSTRAS.LOCALDEORIGEM')">
                            <b-select v-model="localOrigemId">
                                <option :value="null"></option>
                                <template v-for="(item, index) in locaisOrigem">
                                    <option v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </b-field>
                    </template>
                </div>
                <div class="column is-2">
                    <template v-if="config.loteAmostraOrigemDestinoObrigatoria">
                        <ValidationProvider rules="required" name="Local de Destino" ref="localDestinoValidation" v-slot="{ errors, valid }">
                            <b-field :label="$t('INCLUIRLOTEAMOSTRAS.LOCALDEDESTINO')"
                                     :type="{ 'is-danger': errors[0], 'is-success': valid }"
                                     :message="errors">
                                <b-select v-model="localDestinoId" ref="localDestinoSelect">
                                    <option :value="null"></option>
                                    <template v-for="(item, index) in locaisDestino">
                                        <option v-bind:key="index" :value="item.id">
                                            {{item.nome}}
                                        </option>
                                    </template>
                                </b-select>
                            </b-field>
                        </ValidationProvider>
                    </template>
                    <template v-else>
                        <b-field :label="$t('INCLUIRLOTEAMOSTRAS.LOCALDEDESTINO')">
                            <b-select v-model="localDestinoId">
                                <option :value="null"></option>
                                <template v-for="(item, index) in locaisDestino">
                                    <option v-bind:key="index" :value="item.id">
                                        {{item.nome}}
                                    </option>
                                </template>
                            </b-select>
                        </b-field>
                    </template>
                </div>
            </article>
            <article class="level">
                <div class="level-left"></div>
                <div class="level-right">
                    <div class="column is-5">
                        <b-field horizontal>
                            <template #label><i><strong>{{$t('INCLUIRLOTEAMOSTRAS.LATITUDE')}}</strong></i></template>
                            <input type="text" v-model="latitude" class="input is-small" style="width: 89%;" readonly/>
                        </b-field>
                    </div>
                    <div class="column is-5">
                        <b-field horizontal>
                            <template #label><i><strong>{{$t('INCLUIRLOTEAMOSTRAS.LONGITUDE')}}</strong></i></template>
                            <input type="text" v-model="longitude" class="input is-small" readonly/>
                        </b-field>
                    </div>
                </div>
            </article>
        </div>

        <nav class="notification is-light level">
            <div class="level-item">
                <p class="buttons">
                    <b-button type="is-danger" icon-left="close-circle" @click="$router.back();">
                        {{$t('INCLUIRLOTEAMOSTRAS.CANCELAR')}}
                    </b-button>
                    
                    <b-button type="is-success" icon-left="check-circle" @click="enviarLote();">
                        {{$t('SISTEMA.ENVIAR')}}
                    </b-button>
                </p>
            </div>
        </nav>
    </section>
</template>


<script>
    import titlebar from '@/components/titlebar.vue'
    import { mapState } from 'vuex'
    import modalAmostraTempInadequada from '@/components/atendimento/modalAmostraTempInadequada.vue'

    export default {
        data() {
            return {
                amostras: [],
                amostraCodigoDeBarras: "",
                temperatura: "",
                latitude: "",
                longitude: "",
                erros: [],
                checkedRows: [],
                loading: false,
                checkboxPosition: 'left',
                descricao: "",
                acondicionamento: 0,
                locaisOrigem: [],
                locaisDestino: [],
                localOrigemId: null,
                localDestinoId: null,
            }
        },
        components: {
            titlebar
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.EXAME'),
                    this.$t('INCLUIRLOTEAMOSTRAS.LOTEAMOSTRAS'),
                    this.$t('SISTEMA.ENVIAR'),
                ]
            },
            amostraBipada() {
                return this.amostras.some(a => a.amostraId) && this.acondicionamento != 0;
            },
            ...mapState([
                'config',
                'unidadeId',
            ]),
        },
        created() {
        this.$getLocation({})
            .then(coordinates => {
                this.latitude = coordinates.lat
                this.longitude = coordinates.lng
            });

            this.$http.get(`/api/search/LocaisDeOrigem?aplicaFiltrosOrigem=${true}&filtraPostoColetaOuNucleoTecnico=${true}&unidade=${this.unidadeId}`)
                .then(res => {
                    this.locaisOrigem = res.data;
                });

            this.$http.get(`/api/search/LocaisDeOrigem?filtraSomenteNucleoTecnico=${true}&unidade=${this.unidadeId}`)
                .then(res => {
                    this.locaisDestino = res.data;
                });
        },
        methods:{
            async incluirAmostra(){
                this.erros = [];
                this.loading = true;
                const resultValidationLocalOrigem = this.$refs.localOrigemValidation ? await this.$refs.localOrigemValidation.validate() : { valid: true };
                const resultValidationLocalDestino = this.$refs.localDestinoValidation ? await this.$refs.localDestinoValidation.validate() : { valid: true };
                const resultValidationTemperatura = this.$refs.temperaturaValidation ? await this.$refs.temperaturaValidation.validate() : { valid: true };
                if(this.config.loteAmostraOrigemDestinoObrigatoria && (!resultValidationLocalOrigem.valid || !resultValidationLocalDestino.valid || !resultValidationTemperatura.valid)){
                    this.loading = false;
                    if(!resultValidationLocalOrigem.valid){
                        this.$refs.localOrigemSelect.focus();
                        return;
                    }
                    if(!resultValidationLocalDestino.valid){
                        this.$refs.localDestinoSelect.focus();
                        return;
                    }
                    if(!resultValidationTemperatura.valid){
                        this.$refs.temperaturaInput.focus();
                        return;
                    }
                }
                if(this.config.acondicionamentoLoteObrigatorio && this.acondicionamento == 0){
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('INCLUIRLOTEAMOSTRAS.ACONDICIONAMENTOBRIGATORIO'),
                        type: 'is-warning',
                        queue: false
                    });
                    this.loading = false;
                    return;
                }
                if(this.amostraCodigoDeBarras.length !== 0 && this.descricao.length !== 0){
                    this.$http.get(`/api/atendimento/AmostraLoteEnviarIncluir?codigoDeBarras=${this.amostraCodigoDeBarras}&acondicionamento=${this.acondicionamento}`)
                    .then(res => res.data)
                    .then(data => {
                        
                        if (data != null && data.length != 0) {
                            if(data.msg != null){
                                this.$buefy.dialog.alert({
                                    message: data.msg,
                                    type: 'is-warning',
                                    hasIcon: true,
                                    ariaModal: true,
                                    confirmText: this.$t('SISTEMA.OK'),                    
                                    onConfirm: () => {
                                        this.$refs.amostraCodigoDeBarras.focus();
                                    }
                                });
                            }
                            else {
                                const novasAmostras = [];
                                for (const i in data) {
                                    novasAmostras.push(data[i]);
                                }

                                //verificar se o comprimento total excede o limite
                                if (this.amostras.length + novasAmostras.length > this.config.maxAmostrasLote && this.config.maxAmostrasLote != 0) {
                                    this.$buefy.dialog.alert({
                                        message: this.$t('INCLUIRLOTEAMOSTRAS.QUANTIDADEEXCEDIDA', {0: this.config.maxAmostrasLote}),
                                        type: 'is-warning',
                                        hasIcon: true,
                                        ariaModal: true,
                                        confirmText: this.$t('SISTEMA.OK'),
                                        onConfirm: () => {
                                            this.$refs.amostraCodigoDeBarras.focus();
                                        }
                                    });
                                } else {
                                    for (const i in novasAmostras) {
                                        this.amostras.push(novasAmostras[i]);
                                    }
                                }
                            }
                            
                        }
                        else {    
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('INCLUIRLOTEAMOSTRAS.AMOSTRANAOENCONTRADA'),
                                type: 'is-warning',
                                queue: false
                            });

                            this.loading = false;
                            return;
                        }
    
                        this.$refs.amostraCodigoDeBarras.focus();
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.erros.push(error);
                        this.loading = false;
                    }); 
    
                    this.amostraCodigoDeBarras = "";
                }
                else if(!this.descricao.length && this.amostraCodigoDeBarras.length){
                    this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('INCLUIRLOTEAMOSTRAS.SEMDESCRICAO'),
                            type: 'is-warning',
                            queue: false
                        });
                    this.loading = false;
                    return;
                }
                else{
                    this.$buefy.toast.open({
                            duration: 5000,
                            message: this.$t('INCLUIRLOTEAMOSTRAS.AMOSTRANAOENCONTRADA'),
                            type: 'is-warning',
                            queue: false
                        });
                    this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('INCLUIRLOTEAMOSTRAS.SEMDESCRICAO'),
                                type: 'is-warning',
                                queue: false
                            });
                    this.loading = false;
                }
            },
            enviarLote(){
                const amostraLoteEnviar = {
                    amostras: this.checkedRows, 
                    temperatura: this.temperatura, 
                    latitude: this.latitude, 
                    longitude: this.longitude,
                    descricao: this.descricao,
                    localOrigemId: this.localOrigemId,
                    localDestinoId: this.localDestinoId
                };

                
                const amostrasParaValidar = amostraLoteEnviar.amostras.filter(a => this.isInvalidAmostra(a));

                if(amostraLoteEnviar.temperatura !== "" && amostrasParaValidar && amostrasParaValidar.length){
                    this.$buefy.modal.open({
                        parent: this,
                        component: modalAmostraTempInadequada,
                        props: {
                            amostras: amostrasParaValidar,
                            temperaturaInformada: parseFloat(amostraLoteEnviar.temperatura.replace(",", ".")),
                            envioInternoOuPortal: true
                        },
                        events: {
                            enviarLoteApi: () => this.enviarLoteApi(amostraLoteEnviar)
                        },
                        hasModalCard: true,
                        trapFocus: true
                    })
                }
                else{
                    this.enviarLoteApi(amostraLoteEnviar);
                }
            },
            async enviarLoteApi(amostraLoteEnviar){
                this.erros = [];
                this.loading = true;

                const resultValidationLocalOrigem = this.$refs.localOrigemValidation ? await this.$refs.localOrigemValidation.validate() : { valid: true };
                const resultValidationLocalDestino = this.$refs.localDestinoValidation ? await this.$refs.localDestinoValidation.validate() : { valid: true };
                const resultValidationTemperatura = this.$refs.temperaturaValidation ? await this.$refs.temperaturaValidation.validate() : { valid: true };
                if(this.config.loteAmostraOrigemDestinoObrigatoria && (!resultValidationLocalOrigem.valid || !resultValidationLocalDestino.valid || !resultValidationTemperatura.valid)){
                    this.loading = false;
                    if(!resultValidationLocalOrigem.valid){
                        this.$refs.localOrigemSelect.focus();
                        return;
                    }
                    if(!resultValidationLocalDestino.valid){
                        this.$refs.localDestinoSelect.focus();
                        return;
                    }
                    if(!resultValidationTemperatura.valid){
                        this.$refs.temperaturaInput.focus();
                        return;
                    }
                }

                if (this.amostras.length !== 0 && this.checkedRows.length !== 0){
                    this.$http.post(`/api/atendimento/AmostraLoteEnviar`, amostraLoteEnviar )
                        .then(({ data }) => {
                            if(data != null) {
                                this.loading = false;
                                this.$router.push(`/exame/amostralote/${data.id}`)
                            }
                            else {
                                
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t('INCLUIRLOTEAMOSTRAS.AMOSTRANAOENCONTRADA'),
                                    type: 'is-warning',
                                    queue: false
                                });
                                
                                this.loading = false;
                                return;
                            }

                        })
                        .catch((error) => {
                            this.erros.push(error);
                            this.loading = false;
                        });
                    this.amostras = [];
                }
                else if(!this.checkedRows.length){
                    this.$buefy.toast.open({
                    duration: 5000,
                    message: this.$t('INCLUIRLOTEAMOSTRAS.CHECKBOXAMOSTRANAOSELECIONADO'),
                    type: "is-danger",
                    queue: false,
                    });
                    this.loading = false;
                    return;
                }

                else{
                    this.$buefy.toast.open({
                    duration: 5000,
                    message: this.$t('INCLUIRLOTEAMOSTRAS.AMOSTRANAOSELECIONADA'),
                    type: "is-danger",
                    queue: false,
                    });
                    this.loading = false;
                    return;
                }
            },
            isInvalidAmostra(amostra) {
                const temperaturaInformada = parseFloat(this.temperatura.replace(",", "."));

                if(amostra.amostraAcondicionamento &&
                   (amostra.estabilidadeAmbienteDe != null || amostra.estabilidadeAmbienteAte != null ||
                   amostra.estabilidadeCongeladoDe != null || amostra.estabilidadeCongeladoAte != null ||
                   amostra.estabilidadeRefrigeradoDe != null || amostra.estabilidadeRefrigeradoAte != null)){
                    
                    if(amostra.amostraAcondicionamento == 1){
                        // maior que
                        if(amostra.estabilidadeAmbienteDe != null && amostra.estabilidadeAmbienteAte == null){
                            return !(temperaturaInformada >= amostra.estabilidadeAmbienteDe)
                        }
                        // menor que
                        else if(amostra.estabilidadeAmbienteDe == null && amostra.estabilidadeAmbienteAte != null){
                            return !(temperaturaInformada <= amostra.estabilidadeAmbienteAte)
                        }
                        // entre
                        else if(amostra.estabilidadeAmbienteDe != null && amostra.estabilidadeAmbienteAte != null){
                            return !(temperaturaInformada >= amostra.estabilidadeAmbienteDe && temperaturaInformada <= amostra.estabilidadeAmbienteAte)
                        }
                    }
                    else if(amostra.amostraAcondicionamento == 2){
                        // maior que
                        if(amostra.estabilidadeRefrigeradoDe != null && amostra.estabilidadeRefrigeradoAte == null){
                            return !(temperaturaInformada >= amostra.estabilidadeRefrigeradoDe)
                        }
                        // menor que
                        else if(amostra.estabilidadeRefrigeradoDe == null && amostra.estabilidadeRefrigeradoAte != null){
                            return !(temperaturaInformada <= amostra.estabilidadeRefrigeradoAte)
                        }
                        // entre
                        else if(amostra.estabilidadeRefrigeradoDe != null && amostra.estabilidadeRefrigeradoAte != null){
                            return !(temperaturaInformada >= amostra.estabilidadeRefrigeradoDe && temperaturaInformada <= amostra.estabilidadeRefrigeradoAte)
                        }
                    }
                    else if(amostra.amostraAcondicionamento == 3){
                        // maior que
                        if(amostra.estabilidadeCongeladoDe != null && amostra.estabilidadeCongeladoAte == null){
                            return !(temperaturaInformada >= amostra.estabilidadeCongeladoDe)
                        }
                        // menor que
                        else if(amostra.estabilidadeCongeladoDe == null && amostra.estabilidadeCongeladoAte != null){
                            return !(temperaturaInformada <= amostra.estabilidadeCongeladoAte)
                        }
                        // entre
                        else if(amostra.estabilidadeCongeladoDe != null && amostra.estabilidadeCongeladoAte != null){
                            return !(temperaturaInformada >= amostra.estabilidadeCongeladoDe && temperaturaInformada <= amostra.estabilidadeCongeladoAte)
                        }
                    }
                }
            },
        }
    }
</script>