import { GetterTree } from 'vuex'
import { MutationTree } from 'vuex'
import { NotificacaoMsg } from "./notificacaoMsg"
import { RootState } from '../rootState';

const state: NotificacaoMsg[] = [];

const getters: GetterTree<NotificacaoMsg[], RootState> = {
	Count: (state, getters, rootState) => {
		const maxNotificacoes = rootState.config.quantidadeNotificacoesExibidas;

		// Se o estado for nulo ou indefinido, retorna 0
		if (state == null) {
			return 0;
		}

		// Calcula quantas notifica��es excedem o limite e remove o excesso
		const excess = state.length - maxNotificacoes;
		if (excess > 0) {
			// Remove as notifica��es excedentes
			state.splice(0, excess);
		}

		// Retorna o n�mero de notifica��es ap�s o ajuste	
		return state.length;
	},
}

const mutations: MutationTree<NotificacaoMsg[]> = {
	ADD(state, msg) {
		state.push(msg)
    },
	APAGAR_TODAS(state) {
		state.splice(0)
		localStorage.msgs = []
	},
	APAGAR(state, id) {
		for (let i = 0; i < state.length; i++) {
			if (state[i].id === id) {
				state.splice(i, 1);
				localStorage.msgs = JSON.stringify(state);
				break;
			}
		}
	},    
} 

export default { 
    namespaced: true,
    state,
    getters,
    mutations 
}