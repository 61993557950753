<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <b-loading :active.sync="isLoading"></b-loading>
            <div class="columns">
                <div class="column is-6">
                    <b-field :label="$t('PROGRESSOBANCADA.UNIDADE')">
                        <b-select v-model="unidadeId" @input="loadChartData"
                                  expanded>
                            <option :value="null">Todas</option>
                            <option v-for="(item, index) in unidades"
                                    v-bind:key="index"
                                    :value="item.id">
                                {{item.nome}}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field label="Visualizar">
                        <b-select v-model="dias" @input="loadChartData">
                            <option value="1">últimas 24 horas</option>
                            <option value="2">últimos 2 dias</option>
                            <option value="3">últimos 3 dias</option>
                            <option value="5">últimos 5 dias</option>
                            <option value="7">últimos 7 dias</option>
                            <option value="10">últimos 10 dias</option>
                            <option value="15">últimos 15 dias</option>
                            <option value="20">últimos 20 dias</option>
                            <option value="30">últimos 30 dias</option>
                            <option value="45">últimos 45 dias</option>
                            <option value="60">últimos 60 dias</option>
                            <option value="90">últimos 90 dias</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-2">
                    <b-field :label="$t('PROGRESSOBANCADA.ATUALIZAR')">
                        <b-select v-model="intervalo" @input="setTimer">
                            <option value="0">Nunca</option>
                            <option value="1">Cada minuto</option>
                            <option value="2">Cada 2 minutos</option>
                            <option value="5">Cada 5 minutos</option>
                            <option value="10">Cada 10 minutos</option>
                            <option value="20">Cada 20 minutos</option>
                            <option value="30">Cada 30 minutos</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
        </div>
        <b-notification  v-show="!isLoading && chartData.length == 0">{{$t('PROGRESSOBANCADA.EXAMESNAOECONTRADOS')}}</b-notification>
        <div class="columns is-multiline ">
            <div class="column is-3 is-flex is-flex-direction-column" v-for="item, index in chartData" :key="index">
                <doughnut-chart :chart-data="groupChartData(item)" :height="120" :chart-id="item.numero"></doughnut-chart>
                <div class="panel tile is-parent is-flex is-flex-direction-column is-align-items-stretch">
                    <div class="columns panel-heading tile is-child box">
                        <div class="column is-clipped">
                            <span class="is-size-5 has-text-weight-bold">Mapa de Trabalho {{ item.numero }}</span>
                            <b-tag type="is-black is-pulled-right">{{ $moment(item.dataHora).format("DD/MM/YYYY HH:mm") }}</b-tag>
                            <div v-if="item.descricao != ''" class="is-size-7">
                                {{ item.descricao }}
                            </div>
                        </div>
                    </div>
                    <div class="columns panel-block tile is-child">
                        <div class="column">
                            <span>{{ item.total }} exame</span>
                            <span v-if="item.total > 1">s</span>
                        </div>
                    </div>
                    <div class="panel-block is-justified-between">
                        <a v-if="isInRole('interface-configuracao') || isInRole('guia-bancada-cancelar')"  @click="cancelarBancada(item)" title="Cancelar" class="button is-fullwidth is-danger">
                            <b-icon :icon="'close'"></b-icon>
                        </a>
                        <a :href="'/Analise/DigitarResultadosPor?bancadaNumero=' + item.numero" target="_blank" title="Resultados" class="button is-fullwidth is-primary is-outlined">
                            <b-icon :icon="'keyboard-variant'"></b-icon>
                        </a>
                        <a @click="visualizaBancada(item)" title="Visualizar" class="button is-fullwidth is-success">
                            <b-icon :icon="'eye'"></b-icon>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import { mapState, mapGetters } from 'vuex'
    import DoughnutChart from '@/components/Charts/DoughnutChart'

    export default {
        data() {
            return {
                dias: 1,
                unidadeId: null,
                chartData: [],
                intervalo: 0,
                timer: null,
                isLoading: true,
                ordena: localStorage.bancadaOrdenaPelaOrdemLote != null && localStorage.bancadaOrdenaPelaOrdemLote ? 'ordemLote' :
                    localStorage.bancadaOrdenaPeloNomeDoPaciente != null && localStorage.bancadaOrdenaPeloNomeDoPaciente ? 'nomePaciente' :
                    localStorage.bancadaOrdenaPelaDataDeSolicitacao != null && localStorage.bancadaOrdenaPelaDataDeSolicitacao ? 'dataSolicitacao' :
                    localStorage.bancadaOrdenaPeloCodigoDaGuia != null && localStorage.bancadaOrdenaPeloCodigoDaGuia ? 'codigo' : 'ordemLote',
                imprimeRascunho: localStorage.bancadaExibeRascunho ?? false,
                exibeConvenio: localStorage.bancadaExibeConvenio ?? false,
                exibeMedico: localStorage.bancadaExibeNomeDoMedico ?? false,
                exibeMaterial: localStorage.bancadaExibeMaterial ?? false,
                exibeConservante: localStorage.bancadaExibeConservante ?? false,
                exibeLocal: localStorage.bancadaExibeLocal ?? false,
                exibeDataColeta: localStorage.bancadaExibeDataDeColeta ?? false,
                exibeDataRetorno: localStorage.bancadaExibeDataDeRetorno ?? false,
                exibeDataNascimento: localStorage.bancadaExibeNascimento ?? false,
                exibeInscricao: localStorage.bancadaExibeInscricao ?? false,
                exibeLeito: localStorage.bancadaExibeLeito ?? false,
                exibeUltimosResultados: localStorage.bancadaExibeUltimosResultados ?? false,
                emitepapelRazao: localStorage.bancadaEmiteEmPapelRazao ?? false,
                incluirEspacoAssinatura: localStorage.bancadaIncluiEspacoParaAssinaturas ?? false,
                exibeCodigoBarras: localStorage.bancadaExibeCodigoDeBarrasDaAmostra ?? false,
                exibeObservacaoPaciente: localStorage.bancadaExibeObservacaoPaciente ?? false,
                separaSetores: localStorage.bancadaSeparaSetoresPorPagina ?? true,
                separaLocais: localStorage.bancadaSeparaLocaisPorPagina ?? false,
                separaExames: localStorage.bancadaSeparaExamesPorPagina ?? false,
                separaPacientes: localStorage.bancadaSeparaPacientesPorPagina ?? false,
            }
        },
        components: {
            titlebar,
            DoughnutChart
        },
        computed: {
            titleStack() { 
                return [
                    'Análise',
                    this.$t('PROGRESSOBANCADA.PROGRESSOBANCADA')
                ]
            },
            ...mapState([
                'config',
                'unidades',
                'unidadeId'
            ]),
            ...mapGetters([
                'isInRole'
            ])
        },
        mounted() {
            this.loadChartData()
            this.carregarOpcoesBancada()
        },
        beforeDestroy() {
            if (this.timer != null) {
                clearInterval(this.timer)
            }
        },
        methods: {
            loadChartData() {
                this.isLoading = true
                this.chartData = []
                let url = '/api/analitico/progressoBancada?dias=' + this.dias
                if (this.unidadeId != null)
                    url += '&unidadeId=' + this.unidadeId
                this.$http.get(url)
                    .then(res => {
                        this.chartData = res.data
                    }, err => {
                        console.log(err)
                        this.$buefy.toast.open({
                            message: "Não foi possível buscar os dados requisitados. Verifique o log!",
                            type: 'is-danger'
                        })
                    }).finally(function () {
                        this.isLoading = false
                    })
            },
            groupChartData(item) {
                return [
                    {
                        'status': 'Material entregue',
                        'cor': '#ff9900',
                        'qtde': item.total - (item.digitados + item.assinados)
                    },
                    {
                        'status': 'Digitados',
                        'cor': '#0066ff',
                        'qtde': item.digitados
                    },
                    {
                        'status': 'Assinados',
                        'cor': '#339966',
                        'qtde': item.assinados
                    }
                ]
            },
            setTimer() {
                if (this.timer != null) {
                    clearInterval(this.timer)
                }
                if (this.intervalo > 0) {
                    this.timer = setInterval(this.loadChartData, this.intervalo * 60 * 1000)
                }
            },
            cancelarBancada(item) {
                this.$buefy.dialog.confirm({
                    title: 'Cancelar mapa de trabalho',
                    message: 'Tem certeza que deseja <b>cancelar</b> o mapa de trabalho <b>' + item.numero + '</b>? Esta ação não pode ser desfeita.',
                    confirmText: 'Excluir',
                    cancelText: 'Cancelar',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        //mandar ajax para cancelar
                        this.$http.get('/api/analitico/CancelarBancada?numero=' + item.numero)
                            .then(res => {
                                //se receber confirmação ele tira da lista
                                if (res.data) {
                                    this.chartData = this.chartData.filter(x => x.numero != item.numero);
                                }
                            });
                    }
                })
            },
            visualizaBancada(item){
                const bancadaModel = {
                    'SeparaSetoresPorPagina' : this.separaSetores,
                    'SeparaLocaisPorPagina': this.separaLocais,
                    'SeparaExamesPorPagina': this.separaExames,
                    'SeparaPacientesPorPagina': this.separaPacientes,

                    'OrdenaPeloCodigoDaGuia': this.ordena == 'codigo',
                    'OrdenaPeloNomeDoPaciente': this.ordena == 'nomePaciente',
                    'OrdenaPelaDataDeSolicitacao': this.ordena == 'dataSolicitacao',
                    'OrdenaPelaOrdemLote': this.ordena == 'ordemLote',

                    'ExibeRascunho': this.imprimeRascunho,
                    'ExibeConvenio': this.exibeConvenio,
                    'ExibeNomeDoMedico': this.exibeMedico,
                    'ExibeMaterial': this.exibeMaterial,
                    'ExibeConservante': this.exibeConservante,
                    'ExibeLocal': this.exibeLocal,
                    'ExibeDataDeColeta': this.exibeDataColeta,
                    'ExibeDataDeRetorno': this.exibeDataRetorno,
                    'ExibeNascimento': this.exibeDataNascimento,
                    'ExibeInscricao': this.exibeInscricao,
                    'ExibeLeito': this.exibeLeito,
                    'ExibeUltimosResultados': this.exibeUltimosResultados,
                    'EmiteEmPapelRazao': this.emitepapelRazao,
                    'IncluiEspacoParaAssinaturas': this.incluirEspacoAssinatura,
                    'ExibeCodigoDeBarrasDaAmostra': this.exibeCodigoBarras,
                    'ExibeObservacaoPaciente': this.exibeObservacaoPaciente,
                    
                    'Numero': item.numero,
                    'Descricao': item.descricao,
                };

                this.loading = true;
                this.$http.post('/api/analitico/BancadaRel', bancadaModel, { responseType: 'arraybuffer' })
                    .then(res => res.data)
                    .then(data => {
                        const file = new Blob([data], { type: 'application/pdf' });
                        const fileURL = window.URL.createObjectURL(file);
                        window.open(fileURL);
                    })
                    .catch((error) => {
                        if (error.status == 400) {
                            const body = JSON.parse(new TextDecoder().decode(error.body));
                            
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: body?.message || `A bancada ${this.numeroBancada} não existe.`,
                                type: 'is-danger'
                            })
                        }
                        else {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('PROGRESSOBANCADA.ERRORVISUALIZARBANCADA'),
                                type: 'is-danger'
                            })
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
                
            },
            carregarOpcoesBancada() {
                this.opcoesBancada = '';

                this.carregarOpcao('SeparaSetoresPorPagina');
                this.carregarOpcao('SeparaLocaisPorPagina');
                this.carregarOpcao('SeparaExamesPorPagina');
                this.carregarOpcao('SeparaPacientesPorPagina');
                this.carregarOpcao('SomenteExamesUrgentes');
                this.carregarOpcao('SomenteExamesSemResultado');
                this.carregarOpcao('ExibeConvenio');
                this.carregarOpcao('ExibeNomeDoMedico');
                this.carregarOpcao('ExibeMaterial');
                this.carregarOpcao('ExibeConservante');
                this.carregarOpcao('ExibeLocal');
                this.carregarOpcao('ExibeDataDeColeta');
                this.carregarOpcao('ExibeDataDeRetorno');
                this.carregarOpcao('ExibeLeito');
                this.carregarOpcao('ExibeRascunho');
                this.carregarOpcao('ExibeUltimosResultados');
                this.carregarOpcao('ExibeNascimento');
                this.carregarOpcao('ExibeInscricao');
                this.carregarOpcao('OrdenaPeloCodigoDaGuia');
                this.carregarOpcao('OrdenaPeloNomeDoPaciente');
                this.carregarOpcao('OrdenaPelaDataDeSolicitacao');
                this.carregarOpcao('OrdenaPelaOrdemLote');
                this.carregarOpcao('EmiteEmPapelRazao');
                this.carregarOpcao('IncluiEspacoParaAssinaturas');
                this.carregarOpcao('ExibeCodigoDeBarrasDaAmostra');
                this.carregarOpcao('ExibeObservacaoPaciente');
            },
            carregarOpcao(nome) {
                const pre = "bancada";

                if (localStorage.getItem(pre + nome) && localStorage.getItem(pre + nome) == 'true') {
                    this.opcoesBancada += this.opcoesBancada + nome + ",";
                }
            },
        }
    }

</script>