<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="columns is-multiline is-vcentered box">
            <div class="column is-full">
                <b-checkbox
                    v-model="ocultarLotesDeIntegracao"
                    @input="loadAsyncData"
                    class="is-pulled-right"
                >
                    {{$t('LISTALOTEAMOSTRA.OCULTARLOTESDEINTEGRACAO')}} 
                </b-checkbox>
            </div>
            <div class="column is-5">
                <div class="columns">
                    <div class="column is-6">
                        <b-field>
                            <b-input :placeholder="$t('LISTALOTEAMOSTRA.PROCURADESCRICAO')"
                                    v-model="procurar"
                                    icon="magnify"
                                    @input="loadAsyncData"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-datepicker editable :placeholder="$t('LISTALOTEAMOSTRA.SELECIONAPERIODO')"
                            v-model="datas"
                            position="is-bottom-right"
                            @input="loadAsyncData"
                            range>
                        </b-datepicker>           
                    </div>     
                </div> 
            </div> 
            <div class="column">
                <div class="columns">
                    <div class="column is-12">
                        <div class=" is-justified-between">
                            <router-link
                                :to="{ name: 'amostraloteenviar', query: { unidadeId: unidadeId } }"
                                class="button is-success">
                                {{$t('LISTALOTEAMOSTRA.INCLUIR')}}         
                            </router-link>
                        </div>  
                    </div>  
                </div>               
            </div>           
            <div class="column custom-width-bselect">
                <b-field>
                    <b-select :placeholder="$t('INCLUIRLOTEAMOSTRAS.LOCALDEORIGEM')" v-model="localOrigemId" @input="loadAsyncData">
                        <option :value="null"></option>
                        <template v-for="(item, index) in locais">
                            <option v-bind:key="index" :value="item.id">
                                {{item.nome}}
                            </option>
                        </template>
                    </b-select>
                </b-field>
            </div>
            <div class="column custom-width-bselect">
                <b-field>
                    <b-select :placeholder="$t('INCLUIRLOTEAMOSTRAS.LOCALDEDESTINO')" v-model="localDestinoId" @input="loadAsyncData">
                        <option :value="null"></option>
                        <template v-for="(item, index) in locais">
                            <option v-bind:key="index" :value="item.id">
                                {{item.nome}}
                            </option>
                        </template>
                    </b-select>
                </b-field>
            </div>
            <div class="column custom-width is-1">
                <div class="columns">
                    <div class="column is-12">
                        <form @submit.prevent="abrirCadastroInput">
                            <b-field>
                                <b-input :placeholder="$t('LISTALOTEAMOSTRA.CODIGO')"
                                        v-model="id"
                                        type="text"
                                        icon-right="edit"
                                        icon-right-clickable
                                        @icon-right-click="abrirCadastroInput">
                                </b-input>
                            </b-field>
                        </form>
                    </div>  
                </div>
            </div>
        </div>
        <b-table :data="model.lista"
                 :loading="loading"
                 paginated
                 striped
                 hoverable
                 backend-pagination
                 :total="total"
                 @page-change="onPageChange"
                 :aria-next-label="$t('LISTALOTEAMOSTRA.PROXPAGINA')"
                 :aria-previous-label="$t('LISTALOTEAMOSTRA.PAGINAANTERIOR')"
                 :aria-page-label="$t('LISTALOTEAMOSTRA.PAGINA')"
                 :aria-current-label="$t('LISTALOTEAMOSTRA.PAGINAATUAL')"
                 backend-sorting
                 :default-sort-direction="defaultOrdenar"
                 :default-sort="[ordenarPor, ordenar]"
                 sort-icon-size="is-small"
                 @sort="onSort">

            <template @click="abrirCadastro(props.row.id)">
                <b-table-column  v-slot="props" field="id" :label="$t('LISTALOTEAMOSTRA.ID')" sortable>
                    {{ props.row.id }}
                </b-table-column>

                <b-table-column v-slot="props" field="Descricao" :label="$t('LISTALOTEAMOSTRA.DESCRICAO')" sortable>
                    <p style="word-break: break-word">{{ props.row.descricao }}</p>
                </b-table-column>
                
                <b-table-column  v-slot="props" field="ConvenioNome" :label="$t('SISTEMA.CONVENIO')" sortable>
                    {{ props.row.convenioNome }}
                </b-table-column>
                
                <b-table-column  v-slot="props" field="Envio" :label="$t('LISTALOTEAMOSTRA.ENVIO')">
                    {{ props.row.envio | data }}
                </b-table-column>
                
                <b-table-column  v-slot="props" field="Recebimento" :label="$t('LISTALOTEAMOSTRA.RECEBIMENTO')">
                    {{ props.row.recebimento | data }}
                </b-table-column>

                <b-table-column  v-slot="props" custom-key="actions" numeric>
                    <router-link :to="{ name: 'amostralote', params: { id: props.row.id }}" class="button is-small is-primary" :title="$t('LISTALOTEAMOSTRA.EDITAR')">
                        <b-icon icon="pencil" size="is-small"></b-icon>
                    </router-link>
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<style scoped>
    /* fullhd até acima */
    @media (min-width: 1920px){
        .custom-width-bselect{
            -webkit-box-flex: 0;
            -ms-flex: none;
            flex: none;
            width: 20%;
        }
    }
    /* desktop larger (resolução media) até fullhd */
    @media (min-width: 1464px) and (max-width: 1919px) {
        .custom-width{
            width: 10.33333% !important;
        }
        .custom-width-bselect{
            -webkit-box-flex: 0;
            -ms-flex: none;
            flex: none;
            width: 20%;
        }
    }
    /* desktop larger (resolução media) */
    @media (min-width: 1407px) and (max-width: 1463px) {
        .custom-width{
            width: 11.33333% !important;
        }
        .custom-width-bselect{
            -webkit-box-flex: 0;
            -ms-flex: none;
            flex: none;
            width: 25%;
        }
    }
    /* desktop e widescreen */
    @media (min-width: 1024px) and (max-width: 1406px) {
        .custom-width{
            width: 16.33333% !important;
        }
        .custom-width-bselect{
            -webkit-box-flex: 0;
            -ms-flex: none;
            flex: none;
            width: 23%;
        }
    }
    /* tablet */
    @media (min-width: 769px) and (max-width: 1023px) {
        .custom-width{
            width: 19.33333% !important;
        }
        .custom-width-bselect{
            -webkit-box-flex: 0;
            -ms-flex: none;
            flex: none;
            width: 50%;
        }
    }
</style>

<script>
    import { mapState,mapGetters } from 'vuex'
    import titlebar from '@/components/titlebar.vue'

    export default {
        data() {
            return {
                model: [],
                total: 0,
                loading: false,
                procurar: this.$route.query.procurar ?? '',
                ordenarPor: 'Id',
                ordenar: 'desc',
                defaultOrdenar: 'desc',
                page: 1,
                perPage: 20,
                selecionado: null,
                id: null,
                datas: [
                    this.$moment().subtract(30, 'days').toDate(),
                    this.$moment().toDate()
                ],
                locais: [],
                localOrigemId: null,
                localDestinoId: null,
                ocultarLotesDeIntegracao: true,
            }
        },
        components: {
            titlebar
        },
        computed: {
            ...mapState([
                'unidadeId'
            ]),            
            titleStack() {
                return [
                    this.$t('SIDEBAR.EXAME'),
                    this.$t('LISTALOTEAMOSTRA.LOTEAMOSTRAS'),
                    this.$t('LISTALOTEAMOSTRA.LISTAR'),
                ]
            },
            ...mapGetters([
                'isInRole'
            ])
        },
        methods: {
           loadAsyncData() {
                const params = [
                    `procurar=${this.procurar}`,
                    `ordem=${this.ordenarPor}.${this.ordenar}`,
                    `page=${this.page}`,
                    `perPage=${this.perPage}`,
                    `dataInicial=${this.$moment(this.datas[0]).format()}`,
                    `dataFinal=${this.$moment(this.datas[1]).format()}`,
                    this.localOrigemId ? `localOrigem=${this.localOrigemId}` : '',
                    this.localDestinoId ? `localDestino=${this.localDestinoId}` : '',
                    `ocultarLotesDeIntegracao=${this.ocultarLotesDeIntegracao}`
                ].join('&')

                this.loading = true
                this.$http.get(`/api/atendimento/amostralotelistar?${params}`)
                    .then(({ data }) => {
                        this.model = data;
                        if (data.lista.length == 0 && this.page > 1) {
                            this.page = 1
                            this.loadAsyncData()
                        }
                        this.total = this.perPage * data.pageCount
                        this.loading = false
                        this.$router.replace({query: {procurar: this.procurar, page: this.page }})
                    })
                    .catch((error) => {
                        this.model = []
                        this.total = 0
                        this.loading = false
                        throw error
                    })
            },
            onPageChange(page) {
                this.page = page
                this.loadAsyncData()
            },
            onSort(field, order) {
                this.ordenarPor = field
                this.ordenar = order
                this.loadAsyncData()
            },
            abrirCadastro() {
                this.$router.push({ path: `/exame/amostralote/${this.selecionado.id}` })
            },
            abrirCadastroInput() {
                this.$router.push({ path: `/exame/amostralote/${this.id}` })
            },
        },
        mounted() {
            this.loadAsyncData()
            
            this.$http.get(`/api/search/LocaisDeOrigem?filtraPostoColetaOuNucleoTecnico=${true}`)
                .then(res => {
                    this.locais = res.data;
                });
        }
    }
</script>