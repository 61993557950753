<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <div class="columns is-multiline">               
                <div class="column is-4">
                    <b-field :label="$t('PROCURAEXAME.NUMEROGUIA')">
                        <b-input type="number" v-model="guiaId" @keyup.native.enter="carregarExames()"></b-input>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field :label="$t('PROCURAEXAME.REFERENCIA')">
                        <b-input type="text" v-model="referencia"></b-input>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-field :label="$t('PROCURAEXAME.INSCRICAOPACIENTE')">
                        <b-input type="text" v-model="inscricaoPaciente"></b-input>
                    </b-field>
                </div>
                <div class="column is-4">
                    <searchIdName :id.sync="unidade.id" :item.sync="unidade" table="Unidade" :isBuscarExame="true" :label="$t('PROCURAEXAME.UNIDADE')" />
                </div>
                <div class="column is-4">
                    <searchIdName :id.sync="local.id" :item.sync="local" table="Local" :isBuscarExame="true" :label="$t('PROCURAEXAME.LOCAL')" />
                </div>
                <div class="column is-4">
                    <searchIdName :id.sync="convenio.id" :item.sync="convenio" table="Convenio" :isBuscarExame="true" :label="$t('SISTEMA.CONVENIO')" />
                </div>
                <div class="column is-4">
                    <searchIdName :id.sync="paciente.id" :item.sync="paciente" table="Paciente" :isBuscarExame="true" :label="$t('SISTEMA.PACIENTE')" :campos="[{nome:'Nasc', campo: 'dataDeNascimentoFormatada'}, {nome:'...CPF', campo: 'cpf'}]" />
                </div>
                <div class="column is-4">
                    <searchIdName :id.sync="responsavel.id" :item.sync="responsavel" table="Paciente" :isBuscarExame="true" :label="$t('PROCURAEXAME.RESPONSAVEL')" />
                </div>
                <div class="column is-4">
                    <searchIdName :id.sync="setor.id" :item.sync="setor" table="Setor" :isBuscarExame="true" :label="$t('PROCURAEXAME.SETOR')" />
                </div>
                <div class="column is-4">
                    <searchIdName :id.sync="exame.id" :item.sync="exame" table="Exame" :isBuscarExame="true" :label="$t('PROCURAEXAME.EXAME')" searchField="texto" :campos="[{nome:'Apelido', campo: 'apelido'},{nome: 'Setor', campo: 'setorNome'}]" />
                </div>
                <div class="column is-4">
                    <b-field :label="$t('PROCURAEXAME.EXAMESTATUS')">
                        <b-taginput v-model="statusSelecionados"
                                    :data="statusLista"
                                    autocomplete
                                    :allow-new="false"
                                    :before-adding="(item) => { this.statusLista = this.status; return item;}"
                                    :open-on-focus="openOfFocus"
                                    field="status"
                                    icon="label"
                                    :placeholder="$t('PROCURAEXAME.SELECIONESTATUS')"
                                    @typing="buscarStatus"
                                    ref="tagInput">
                        </b-taginput>
                    </b-field>
                    
                </div>
                <div class="column">
                    <b-field  :label="$t('PROCURAEXAME.RESULTADOPAGINA')">
                        <b-select  v-model="perPage" :disabled="!isPaginated">
                            <option value="10">10</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </b-select>
                    </b-field>                 
                </div>
                <div class="column">
                    <b-field>
                        <div class="checkbox-wrapper">
                            <label for="exameAtrasado-checkbox" class="checkbox-label">
                                {{$t('PROCURAEXAME.EXAMEATRASADO')}}
                            </label>
                            <b-checkbox id="exameAtrasado-checkbox" v-model="examesAtrasados"></b-checkbox>
                        </div>
                    </b-field>
                </div>
                <div class="container">
                    <div class="column is-6">
                        <div class="panel">
                            <p class="panel-tabs">
                                <a :class="{ 'is-active': dataFiltro == 'emissao' }" @click="dataFiltro = 'emissao'">{{$t('PROCURAEXAME.EMISSAO')}}</a>
                                <a :class="{ 'is-active': dataFiltro == 'solicitacao' }" @click="dataFiltro = 'solicitacao'">{{$t('PROCURAEXAME.SOLICITACAO')}}</a>
                                <a :class="{ 'is-active': dataFiltro == 'coleta' }" @click="dataFiltro = 'coleta'">{{$t('PROCURAEXAME.COLETA')}}</a>
                                <a :class="{ 'is-active': dataFiltro == 'retorno' }" @click="dataFiltro = 'retorno'">{{$t('PROCURAEXAME.RETORNO')}}</a>
                                <a :class="{ 'is-active': dataFiltro == 'entrega' }" @click="dataFiltro = 'entrega'">{{$t('PROCURAEXAME.ENTREGA')}}</a>
                            </p>
                            <div class="panel-block">
                                <periodoHora :dataInicial.sync="datas[0]" :dataFinal.sync="datas[1]" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         
            <div style="display: flex; justify-content: center; align-items: center;">
                <b-button type="is-success" :loading="loading" @click="carregarExames()" style="display: flex; align-items: center;">
                    <b-icon icon="magnify"></b-icon>
                    <span class="button-label">{{$t('PROCURAEXAME.BUSCAR')}}</span>
                </b-button>
            </div>
        </div>

        <div class="box" v-if="!searchInitiated && exames.length === 0">
            <div class="has-text-centered">
                <p>{{ $t('PROCURAEXAME.BOTAOPARABUSCA') }}</p>
            </div>
        </div>

        <div class="box" v-if="exames.length > 0">
            <div class="columns">
                <div class="column  is-justified-between">
                    <span class="label">{{$t('PROCURAEXAME.EXAMES')}}</span>
                    <b-modal :active.sync="isModalAlteracaoPrazo" has-modal-card trap-focus aria-role="dialog" aria-modal>
                        <modalAlteracaoPrazo :motivos="motivosAlteracao" :data.sync="checkedRows"></modalAlteracaoPrazo>
                    </b-modal>
                    <b-button v-if="checkedRows != null && checkedRows.length > 0" type="is-success" @click="isModalAlteracaoPrazo = true">
                        {{$t('PROCURAEXAME.ALTERARPRAZO')}}
                    </b-button>
                </div>
            </div>
            <b-table :data="exames"
                     :loading="loading"
                     striped
                     :total="total"
                     :paginated="isPaginated"
                     backend-pagination
                     @page-change="onPageChange"
                     :aria-next-label="$t('PROCURAEXAME.PROXPAGINA')"
                     :aria-previous-label="$t('PROCURAEXAME.PAGINAANTERIOR')"
                     :aria-page-label="$t('PROCURAEXAME.PAGINA')"
                     :aria-current-label="$t('PROCURAEXAME.PAGINAATUAL')"
                     :checked-rows.sync="checkedRows"
                     checkable
                     :checkbox-position="checkboxPosition"
                     :per-page="perPage">

                <template>
                    <b-table-column v-slot="props" field="# Guia" :label="$t('PROCURAEXAME.GUIA')">
                        <router-link :to="{ name: 'guia', params: { id: props.row.guiaId }}">
                            {{ props.row.guiaId }}-{{ props.row.item }}
                        </router-link>
                        <p class="small-text" v-if="props.row.referencia">{{ props.row.referencia }}</p>
                    </b-table-column>

                    <b-table-column v-slot="props" field="Nome do Paciente" :label="$t('PROCURAEXAME.PACIENTENOME')">
                        <a :href="'/atendimento/paciente/' + props.row.pacienteId">{{ props.row.pacienteNome }}</a>
                        <div class="inline-container">
                            <p class="small-text" v-if="props.row.pacienteDataNascimento">Nasc: {{ $moment(props.row.pacienteDataNascimento).format("DD/MM/YYYY") }}</p>
                            <p class="small-text" v-if="props.row.pacienteCpf">CPF: {{ props.row.pacienteCpf }}</p>
                            <p class="small-text" v-if="props.row.inscricaoPaciente">Inscrição: {{ props.row.inscricaoPaciente }}</p>
                        </div>
                    </b-table-column>

                    <!--<b-table-column field="Código da Amostra" label="Código da Amostra">
        {{ props.row.amostraCodigo }}
    </b-table-column>-->

                    <b-table-column v-slot="props" field="Apelido" :label="$t('PROCURAEXAME.EXAME')">
                        <a v-if="props.row.status & 4 && !(props.row.status & 1)" :href="'/guia/resultado?guiaId=' + props.row.guiaId + '&item=' + props.row.item">
                            {{ props.row.exameNome }}
                        </a>
                        <span v-else>
                            {{ props.row.exameNome }}
                        </span>
                        <div class="inline-container">
                            <p class="small-text" v-if="props.row.exameApelido">{{ props.row.exameApelido }}</p>
                            <p class="small-text" v-if="props.row.setorNome"> {{$t('PROCURAEXAME.SETOR')}}: {{ props.row.setorNome }}</p>
                        </div>
                    </b-table-column>

                    <b-table-column v-slot="props" field="Prazo de entrega" :label="$t('PROCURAEXAME.RETORNO')">
                        <span v-if="props.row.dataEntregaExame">
                            {{ $moment(props.row.dataEntregaExame).format("DD/MM/YYYY") }}
                        </span>
                        <div class="inline-container">
                            <p class="small-text" v-if="props.row.dataSolicitacaoExame"> {{$t('PROCURAEXAME.SOLICITACAO')}}:  {{ $moment(props.row.dataSolicitacaoExame).format("DD/MM/YYYY") }}</p>
                        </div>
                    </b-table-column>
                    <b-table-column v-slot="props" field="Status" :label="$t('PROCURAEXAME.STATUS')">
                        <b-tooltip position="is-bottom" multilined type="is-dark">
                            <b-tag class="prds-tag has-text-centered is-size-6" :style="props.row.statusHtmlStyle">
                                <span class="sigla">{{ props.row.statusSigla }}</span>
                            </b-tag>
                            <template v-slot:content>
                                <b>Status: {{ props.row.statusSiglaCompleta }}</b>
                                <br>
                                <p>Histórico: {{ props.row.statusTitle }}</p>
                            </template>
                        </b-tooltip>
                    </b-table-column>

                    <b-table-column>
                        <template slot-scope="props">
                            <b-tooltip label="Abrir todos os resultados da guia" position="is-left">
                                <a style="margin-right:4px" @click="visualizarPdf(props.row.guiaId, props.row.laudoRestrito)">
                                    <b-icon icon="file-pdf" class="has-text-info"></b-icon>
                                </a>
                            </b-tooltip>
                            <b-tooltip v-if="props.row.obsExame" :label="props.row.obsExame" position="is-left">
                                <b-icon icon="information" class="has-text-info"></b-icon>
                            </b-tooltip>
                            <b-icon v-else icon="information" class="has-text-grey-light" disabled></b-icon>
                        </template>
                    </b-table-column>
                </template>
            </b-table>
        </div>
    </section>
</template>


<style scoped>
    .prds-tag {
        border-radius: 16px;
    }

    .inline-container {
        display: flex; 
        align-items: flex-start; 
        white-space: nowrap; 
    }

    .inline-container p {
        margin-right: 10px; 
    }
   
    .button-label {
        margin-left: 0.5rem; 
    }

    .checkbox-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .checkbox-label {
        font-weight: bold; 
        margin-bottom: 0.25rem; 
        position: relative;
        top: -0.25rem;
    }

    .small-text {
        font-size: 0.75rem;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
       
    }

    

    
</style>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'
    import modalAlteracaoPrazo from '@/components/atendimento/modalAlteracaoPrazo.vue'
    import periodoHora from '@/components/periodoHora.vue'

    export default {
        components: {
            titlebar,
            searchIdName,
            modalAlteracaoPrazo,
            periodoHora
        },
        computed: {
            titleStack() {
                return [
                    this.$t('SIDEBAR.EXAME'),
                    this.$t('PROCURAEXAME.PROCURAEXAME'),
                ]
            },
            ...mapState([
                'status',
            ])
        },
        created() {
            // se passou algum parametro
            if (this.$route.query) {
                if (this.$route.query.setorid) {
                    this.setor.id = parseInt(this.$route.query.setorid)
                }
                if (this.$route.query.unidadeId) {
                    this.unidade.id = parseInt(this.$route.query.unidadeId)
                }
                if (this.$route.query.convenioId) {
                    this.convenio.id = parseInt(this.$route.query.convenioId)
                }
                if (this.$route.query.contabilizarEntreguesAtrasados) {
                    this.contabilizarEntreguesAtrasados = this.$route.query.contabilizarEntreguesAtrasados
                }
                if (this.$route.query.status) {
                    switch (this.$route.query.status) {
                        case 'Atrasados':
                            this.examesAtrasados = true
                            break;
                        case 'Aguardando material':
                            this.statusSelecionados = ['Solicitado']
                            break;
                        case 'Material entregue':
                            this.statusSelecionados = ['MaterialEntregue']
                            break;
                        case 'Mapa de Trabalho emitido':
                            this.statusSelecionados = ['BancadaEmitida']
                            break;
                        case 'Digitados':
                            this.statusSelecionados = ['ResultadoDigitado']
                            break;
                        case 'Assinados':
                            this.statusSelecionados = ['ResultadoAssinado']
                            break;
                    }
                }
                if (this.$route.query.dias) {
                    this.datas = [
                        this.$moment().subtract(this.$route.query.dias, 'days').toDate(),
                        this.$moment().toDate()
                    ]
                }

            }
        },
        watch: {
            status: function(newValue){
                if (newValue){
                    this.statusLista = newValue;
                }
            },
        },
        data() {
            return {
                guiaId: null,
                referencia: null,
                inscricaoPaciente: null,
                openOfFocus: true,
                local: {
                    id: null
                },
                unidade: {
                    id: null
                },
                convenio: {
                    id: null
                },
                paciente: {
                    id: null
                },
                responsavel: {
                    id: null
                },
                setor: {
                    id: null
                },
                exame: {
                    id: null
                },
                datas: [
                    this.$moment().subtract(1, 'days').toDate(),
                    this.$moment().toDate()
                ],
                dataFiltro: 'solicitacao',
                statusSelecionados: [],
                statusLista: this.status,
                exames: [],
                loading: false,
                checkedRows: [],
                checkboxPosition: 'left',
                page: 1,
                total: 0,
                isModalAlteracaoPrazo: false,
                motivosAlteracao: [],
                examesAtrasados: false,
                perPage: 10,
                isPaginated: true,
                contabilizarEntreguesAtrasados: false,
                searchInitiated: false
            }
        },
        methods: {
            buscarStatus(text) {
                this.statusLista = this.status;
                this.statusLista = this.statusLista.filter((option) => {
                    return option
                        .toString()
                        .toLowerCase()
                        .indexOf(text.toLowerCase()) >= 0
                })
            },
            carregarExames(){

                //this.$router.replace({
                //    query: {
                //        setorid: this.setor.id
                //    }
                //})
                this.searchInitiated = true
                const params = [
                    `pageNumber=${this.page}`,
                    `filtrarPor=${this.dataFiltro}`,
                    `statusExames=${this.statusSelecionados.toString()}`,
                    `dataInicial=${this.$moment(this.datas[0]).format('YYYY-MM-DDTHH:mm:ss') }`,
                    `dataFinal=${this.$moment(this.datas[1]).format('YYYY-MM-DDTHH:mm:ss')}`,
                    `perPage=${this.perPage}`,
                    `contabilizarEntreguesAtrasados=${this.contabilizarEntreguesAtrasados}`
                ]
                if(this.unidade?.id != null)
                {
                    params.push(`unidadeId=${this.unidade.id}`)
                }

                if(this.guiaId != null){
                    params.push(`guiaId=${this.guiaId}`);
                }

                if (this.referencia != null) {
                    params.push(`referencia=${this.referencia}`);
                }

                if (this.inscricaoPaciente != null) {
                    params.push(`inscricaoPaciente=${this.inscricaoPaciente}`);
                }

                if(this.local != null && this.local.id != null){
                    params.push(`localId=${this.local.id}`);
                }

                if(this.setor != null && this.setor.id != null){
                    params.push(`setorId=${this.setor.id}`);
                }

                if(this.exame != null && this.exame.id != null){
                    params.push(`exameId=${this.exame.id}`);
                }

                if(this.convenio != null && this.convenio.id != null){
                    params.push(`convenioId=${this.convenio.id}`);
                }

                if(this.paciente != null && this.paciente.id != null){
                    params.push(`pacienteId=${this.paciente.id}`);
                }

                if(this.responsavel != null && this.responsavel.id != null){
                    params.push(`responsavelId=${this.responsavel.id}`);
                }

                if (this.examesAtrasados) {
                    params.push('examesAtrasados=true')
                }

                this.loading = true;
                this.$http.get(`/api/atendimento/procuradeexames?${params.join('&')}`)
                    .then(({ data }) => {
                        this.exames = data.lista
                        this.total = data.pageCount * 10
                        this.loading = false
                    })
                    .catch((error) => {
                        this.total = 0
                        this.loading = false
                        throw error
                    })

                if(this.motivosAlteracao.length == 0){
                    this.$http.get(`/api/search/motivosalteracaoprazo`)
                        .then(({ data }) => {
                            this.motivosAlteracao = data
                        })
                }

            },
            onPageChange(page) {
                this.page = page
                this.carregarExames()
            },
            visualizarPdf(guiaId, laudoRestrito) {


                window.open('/Guia/Pdf?id=' + guiaId + '&laudoRestrito=' + laudoRestrito);

            },
        }
    }
</script>