<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('MODALCANCELANDODIGITACAOEXAME.CANCELARDIGITACAOEXAME')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">
                <h5 class="is-size-5">{{$t('MODALCANCELANDODIGITACAOEXAME.ATENCAO')}}</h5>
                <p>{{$t('MODALCANCELANDODIGITACAOEXAME.AVISOCANCELAMENTO')}}</p>
            </b-notification>
            <b-notification v-if="erroCancelamentoDigitacaoExame"
                            type="is-danger">
                {{ erroCancelamentoDigitacaoExame }}
            </b-notification>
            <div class="panel">
                <p class="panel-block">
                    <b-field :label="$t('MODALCANCELANDODIGITACAOEXAME.EXAMES')">
                    </b-field>
                </p>
                <p class="panel-block">
                    <b-tag type="is-info" v-for="(exame,index) in selecionado" :key="index">{{ exame.exameApelido }}</b-tag>
                </p>
                <p class="panel-block">
                    <b-field :label="$t('MODALCANCELANDODIGITACAOEXAME.MOTIVOS')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <b-input v-model="procurarMotivosCancelamentoDigitacaoExame"
                             icon="magnify"
                             icon-right="close-circle"
                             icon-right-clickable
                             @icon-right-click="procurarMotivosCancelamentoDigitacaoExame = ''"></b-input>
                </div>
                <div class="panel-block">
                    <b-select multiple
                              native-size="10"
                              expanded
                              v-model="motivoCancelamentoDigitacaoExameSelecionado">
                        <option v-for="(motivo,index) in filteredMotivosCancelamentoExame" :key="index"
                                :value="motivo.id">
                            {{ motivo.descricaoInterno }}
                        </option>
                    </b-select>
                </div>

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-danger"
                    :loading="salvandoDados"
                      @click="confirmarCancelamentoDigitacaoExame">{{$t('MODALCANCELANDODIGITACAOEXAME.CONFIRMARCANCELAMENTO')}}</b-button>
            <b-button @click="$emit('close')">{{$t('MODALCANCELANDODIGITACAOEXAME.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    export default {
        props: {
            guia: Object,
            selecionado: Array
        },
        data() {
            return {
                procurarMotivosCancelamentoDigitacaoExame: '',
                listaMotivosCancelamentoDigitacaoExame: [],
                motivoCancelamentoDigitacaoExameSelecionado: [],
                erroCancelamentoDigitacaoExame: null,
                salvandoDados: false
            }
        },
        computed: {
            filteredMotivosCancelamentoExame() {
                return this.procurarMotivosCancelamentoDigitacaoExame
                    ? this.listaMotivosCancelamentoDigitacaoExame.filter(x=>x.descricaoInterno!=null)
                        .filter(x =>
                            x.descricaoInterno
                                .toUpperCase()
                                .indexOf(this.procurarMotivosCancelamentoDigitacaoExame.toUpperCase()) > -1)
                    : this.listaMotivosCancelamentoDigitacaoExame
            },
        },
        watch: {
            motivoCancelamentoDigitacaoExameSelecionado(newValue) {
                if (newValue.length > 1) {
                    this.motivoCancelamentoDigitacaoExameSelecionado = [newValue[newValue.length - 1]];
                }
            },
        },
        mounted() {
            this.loadListaMotivosCancelamentoExame()
        },
        methods: {
            loadListaMotivosCancelamentoExame() {
                this.$http.get('/api/search/MotivosCancelamentoExame')
                    .then(m => {
                        this.listaMotivosCancelamentoDigitacaoExame = m.data
                    })
            },
            confirmarCancelamentoDigitacaoExame() {
                this.salvandoDados = true

                if(this.motivoCancelamentoDigitacaoExameSelecionado.length == 0) {
                    this.salvandoDados = false;

                    return this.$buefy.toast.open({
                        duration: 5000,
                        message: this.$t('TOAST.MOTIVOCANCELAMENTO'),
                        type: 'is-warning'
                    })
                }

                this.$http.post('/api/atendimento/cancelarDigitacaoExame', {
                    id: this.guia.id,
                    item: this.selecionado.map(x => x.item),
                    motivoIds: this.motivoCancelamentoDigitacaoExameSelecionado
                })
                .then((res) => {
                    this.salvandoDados = false
                    if (res.body.erro) {
                        this.erroCancelamentoDigitacaoExame = res.body.erro
                    } else {
                        this.$emit('loadGuia')
                        this.$emit('close')
                    }
                })
                .catch((res) => {
                    this.salvandoDados = false
                    this.erroCancelamentoDigitacaoExame = res.body.erro;
                })
            },

        }
    }
</script>